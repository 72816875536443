@import './styles/global-variables';

.item {
    padding-right: 50px;
    width: 20%;

    @media (max-width: $tp-breakpoint-max) {
        width: 100%;
    }

    & > * {
        margin-top: 0;
    }

    .feedback {
        margin-top: 69px;

        @media (max-width: $tp-breakpoint-max) {
            margin-bottom: 17px;
            margin-top: 0;
        }

        @media (max-width: $sp-breakpoint-max) {
            margin-bottom: 23px;
        }

        button {
            background-color: transparent;
            border: 0;
            cursor: pointer;
            padding: 0;

            &.disabled {
                cursor: not-allowed;
                opacity: .3;
            }

            .positive-feedback-icon {
                margin-bottom: 2px;
                margin-right: 12px;
            }
        }
    }

    .user-location,
    .total-positive-feedback,
    .review-date,
    .feedback {
        color: $golden;
        font-size: 12px;
    }

    .review-date {
        @media (max-width: $tp-breakpoint-max) {
            margin-bottom: 20px;
        }
    }

    .review-syndicated {
        color: #a1a1a1;
        font-size: 14px;
    }

    .user-nickname {
        font-size: 19px;

        @media (max-width: $tp-breakpoint-max) {
            color: $golden;
            font-size: 12px;
            margin-bottom: 4px;
        }
    }

    .title {
        font-size: 19px;
        margin-bottom: 8px;

        @media (max-width: $tp-breakpoint-max) {
            margin-bottom: 3px;
            margin-top: 27px;
        }

        @media (max-width: $tp-breakpoint-max) {
            margin-top: 18px;
        }
    }

    .review-text {
        color: $text-gray;
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;

        @media (max-width: $tp-breakpoint-max) {
            margin-top: 17px;
        }

        @media (max-width: $sp-breakpoint-max) {
            margin-top: 28px;
        }
    }

    .client-response {
        color: $text-gray;
        margin: 0;
    }

    &:first-child {
        max-width: 225px;

        @media (max-width: $tp-breakpoint-max) {
            width: 100%;
        }
    }

    &:last-child {
        display: flex;
        flex-direction: column;
        padding-right: 10px;
        text-align: right;

        @media (max-width: $tp-breakpoint-max) {
            flex-direction: column-reverse;
            text-align: left;
        }
    }
}

.center {
    border-left: 1px solid $light-orange;
    flex: 1;
    max-width: 650px;
    overflow-wrap: break-word;
    padding: 0 50px;

    @media (max-width: $tp-breakpoint-max) {
        border: 0;
        padding: 0;
    }
}
