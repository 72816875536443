@import './styles/global-variables';

html {
    box-sizing: border-box;
}

*,
::after,
::before {
    box-sizing: inherit;
}

*[tabindex='0'],
button {
    &:focus {
        @include focusable-item;
    }
}

body {
    font-family: Avenir, sans-serif;
    margin: 0;
    padding: 0;
}

.layout {
    @media (max-width: 1390px) {
        margin-top: -204px;
    }

    @media (max-width: $tp-breakpoint-max) {
        margin-top: -2px;
    }
}

a {
    text-decoration: none;
}

button {
    font-family: Avenir, sans-serif;
}

.h1-home {
    display: none;
}

h1 {
    font-family: Didot, serif;
    font-size: 46px;
    font-style: italic;
    font-weight: normal;

    @media (max-width: $tp-breakpoint-max) {
        font-size: 36px;
    }

    @media (max-width: $sp-breakpoint-max) {
        font-size: 28px;
    }

    b,
    strong {
        font-family: Didot, serif;
        font-style: normal;
        font-weight: bold;
    }
}

h2,
h3 {
    font-family: Didot, serif;
    font-size: 36px;
    font-style: normal;
    font-weight: bold;

    @media (max-width: $tp-breakpoint-max) {
        font-size: 34px;
    }

    @media (max-width: $sp-breakpoint-max) {
        font-size: 26px;
    }

    i,
    em {
        font-style: italic;
        font-weight: normal;
    }

    b,
    strong {
        font-family: Didot, serif;
        font-style: normal;
        font-weight: bold;
    }
}

p {
    font-family: Avenir, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
}

.main-container {
    height: auto;
    outline: none;
    overflow: hidden;
    padding-top: 210px;

    @media (max-width: $tp-breakpoint-max) {
        padding-top: 0;
    }
}

.link-md {
    color: #815b30;
    font-weight: bold;
    padding-bottom: 2px;
}

@keyframes fadeIn {
    0% {
        display: block;
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.gold {
    color: #815b30 !important;
}

.slick-dots {
    bottom: -25px;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%;

    li {
        cursor: pointer;
        display: inline-block;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        position: relative;
        width: 20px;

        button {
            background: transparent;
            border: 0;
            color: transparent;
            cursor: pointer;
            display: block;
            font-size: 0;
            height: 20px;
            line-height: 0;
            padding: 5px;
            width: 20px;
        }
    }
}

div[role='main'] {
    >div {
        &:nth-of-type(4) {
            margin-top: unset !important;
        }
    }
}

@media only screen and (min-width:1981px) and (max-width:7680px) {
    div[role='main']>div:nth-of-type(7) {
        margin-top: unset;
    }
}