@import './styles/global-variables';

.search-input {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    width: 20em;

    @media (max-width: $tp-breakpoint-max) {
        width: 35vw;
    }

    @media (max-width: $sp-breakpoint-max) {
        width: 70vw;
    }

    input {
        background-color: transparent;
        border: 0;
        color: $golden;
        font-size: 13px;
        width: 0;

        @media(max-width: $tp-breakpoint-max) {
            font-size: 13px;
        }

        &::placeholder {
            color: $golden;
            font-family: Avenir, sans-serif;
        }

        &.dirty {
            padding: .15vw;
            width: 100%;
        }

        &:focus {
            padding: .15vw;
            transition: width 200ms;
            width: 100%;
        }
    }

    input + label {
        height: 0;
        overflow: hidden;
        width: 0;
    }

    &:hover {
        input {
            padding: .15vw;
            transition: width 200ms;
            width: 100%;
        }
    }

    button {
        background-color: transparent;
        background-image: url('https://images.ctfassets.net/a8l3ylu84syn/4yW4lmIHvPbch6umdqXiwd/7078388c50e7b369e1cedf67aed1e4cb/magnifier__1_.svg');
        background-position: center;
        background-repeat: no-repeat;
        border: 0;
        cursor: pointer;
        margin-right: 2px;

        @media (min-width: $tp-breakpoint-min) {
            &:focus {
                @include focusable-item;
            }
        }

        @media (max-width: $sp-breakpoint-max) {
            background-size: 17px;
            width: 20px;
        }
    }
}
