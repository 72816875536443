@import './styles/global-variables';

.search-results {
    background-position: calc(50% - 760px) bottom, calc(50% + 775px) top;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    min-height: 460px;

    @media (min-width: $content-width-dt) {
        min-height: calc(100vh - 290px);
    }

    .content {
        width: $content-width-dt;

        @media(max-width: $tp-breakpoint-max) {
            width: $content-width-tb;
        }

        @media(max-width: $sp-breakpoint-max) {
            width: $content-width-sp;
        }

        h1 {
            b {

                &::before {
                    content: '\201C';
                    left: -45px;
                    top: 10px;
                }

                &::after {
                    bottom: -30px;
                    content: '\201D';
                    right: -40px;
                }
            }
        }

        .tabs {
            margin-bottom: 30px;

            button {
                background-color: #ebebeb;
                border: 1px solid $dark-gray;
                cursor: pointer;
                font-family: Avenir, sans-serif;
                padding: 20px;
                text-align: center;

                &:first-child {
                    border-right: 0;
                }

                &.active {
                    background-color: white;
                    border-top: 3px solid black;
                    font-weight: bold;
                }
            }
        }

        .search-content {
            margin-bottom: 120px;

            .content-item {
                border-bottom: 1px solid $text-gray;
                padding: 0 5% 45px;

                &:last-child {
                    border: 0;
                }

                p {
                    color: $text-gray;
                }

                .link-btn {
                    @include gold-background;

                    color: black;
                    display: inline-block;
                    font-weight: bold;
                    margin-top: 25px;
                    padding: 16px 32px;
                }
            }
        }
    }
}
