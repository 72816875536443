@import './styles/global-variables';

.product-strengths-section {
    margin: auto;
    max-width: 1080px;
    padding-bottom: 70px;

    .product-strengths-container {
        display: flex;
        flex-wrap: wrap;

        .product-strength {
            align-items: center;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            padding: 1.5vw;
            text-align: center;
            width: 33%;

            .emphasized {
                font-family: Didot, serif;
                font-size: 20px;
            }

            @media (max-width: $tp-breakpoint-max) {
                width: 50%;
              }

            @media (max-width: $sp-breakpoint-max) {
                width: 100%;
              }
        }
  }

    .product-strengths-title {
        p {
            display: block;
            font-family: Didot, serif;
            font-size: 36px;
            font-style: normal;
            text-align: center;
          }
      }
}