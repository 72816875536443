@import './styles/global-variables';

.landingPageCardsCarousel {
    .card {
        max-width: 280px;
        padding: 10px 10px;
        display: flex;
        flex-direction: column;

        .card-1 {

            background-image: url('https://images.ctfassets.net/xq8n4mwvbz58/7v4KNdOawnMc04LeHhQ31q/9d302a588f00ef3d41c7316793e2a7be/Image_placeholder.png?h=250');
            z-index: -9999;

            img {
                margin-left: -15px;
            }
        }



        .column-text {
            background-color: #173F36;
            line-height: 1px;
            padding-left: 10px;
            color: #F5F6F1;
            font-family: 'Didot';

            p {
                min-height: 50px;
                width: 200px;
            }

            h3 {
                font-size: 20px;
            }

            .description-text {
                display: none;
                margin-left: -10px;

                .viewProductButton {
                    width: 200px;
                    margin-left: 35px;
                    padding: 10px 20px;
                    margin-top: 25px;
                    margin-bottom: 15px;
                    outline: none;
                    border: none;
                    font-weight: 400;
                    background-image: linear-gradient(to right, #C49543, #D5B965, #C49241, #D5B965, #C49543);
                }

                .column-text {
                    background: none;
                }
            }
        }
    }

    .card:hover {
        .card-1 {
            background-image: none;
            background-color: #173F36;
            transition: 300ms;

            img {
                opacity: .3;
            }
        }

        .column-text {
            h5 {
                margin-top: -120px;
            }

            .description-text {
                display: block;
            }
        }
    }
}