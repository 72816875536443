@import './styles/global-variables';

.product-variants {

    label,
    span {
        margin: auto;
    }

    select {
        background-color: white;
        border: 1px solid black;
        font-weight: bold;
        margin: 0 3px;
        padding: 10px;
        width: 100px;

        &:focus {
            background-color: $gold-focus;
        }
    }
}
