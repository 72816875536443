@import './styles/global-variables';

.video-block {
    .video-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: hidden;

        .video-inner {
            display: inline-block;
            position: relative;
            background: #000;

            .video-inner-title {
                p {
                    color: white;
                    font-family: 'Didot', serif;
                    font-size: 36px;
                    font-style: normal;
                    margin: 0;
                    position: absolute;
                    text-align: center;
                    top: 10%;
                    width: 100%;
                    z-index: 1;

                    @media (max-width: $sp-breakpoint-max) {
                        color: black;
                        margin-bottom: 30px;
                        margin-left: 10%;
                        position: static;
                        text-align: left;
                        top: 0;
                        width: calc(100% - 50px);
                    }
                }

                @media (max-width: $sp-breakpoint-max) {
                    &.hideable {
                        display: block;
                    }
                }

                &.hideable {
                    display: none;
                }
            }

            .decoration {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                @media (max-width: $tp-breakpoint-max) {
                    display: none;
                }
            }

            .decoration-right {
                right: 0;

                img {
                    margin-left: 51%;
                }
            }

            .decoration-left {
                left: 0;

                img {
                    margin-left: -57%;
                }
            }

            .small-video {
                height: 366px;
                width: 650px;

                @media (max-width: $tp-breakpoint-max) {
                    height: 270px;
                    width: 480px;
                }

                @media (max-width: $sp-breakpoint-max) {
                    height: 56vw;
                    position: relative;
                    width: 100vw;
                }
            }

            .big-video {
                height: 479px;
                width: 850px;

                @media (max-width: $tp-breakpoint-max) {
                    height: 344px;
                    width: 610px;
                }

                @media (max-width: $sp-breakpoint-max) {
                    height: 56vw;
                    position: relative;
                    width: 100vw;
                }
            }

            .video-poster-wrapper {
                bottom: 0;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;

                @media (max-width: $sp-breakpoint-max) {
                    position: static;
                }
            }

            h2 {
                color: white;
                margin: 0;
                position: absolute;
                text-align: center;
                top: 10%;
                width: 100%;
                z-index: 1;

                &.hideable {
                    display: none;
                }

                @media (max-width: $sp-breakpoint-max) {
                    color: black;
                    margin-bottom: 30px;
                    margin-left: 10%;
                    position: static;
                    text-align: left;
                    top: 0;
                    width: calc(100% - 50px);

                    &.hideable {
                        display: block;
                    }
                }
            }

            .video-poster {
                height: 100%;
                width: 100%;
            }

            .play-wrapper {
                align-items: center;
                color: #fff;
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 47%;
                width: 100%;

                @media (max-width: $tp-breakpoint-max) {
                    top: 45%;
                }

                @media (max-width: $sp-breakpoint-max) {
                    top: 35%;
                }

                .arrow-play-video {
                    background-color: rgba(0, 0, 0, .13);
                    border: 1px solid white;
                    border-radius: 50%;
                    height: 63px;
                    position: relative;
                    width: 63px;

                    &::before {
                        border-color: transparent;
                        border-left-color: white;
                        border-style: solid;
                        border-width: 7px 14px;
                        box-sizing: content-box;
                        color: white;
                        content: '';
                        height: 0;
                        left: 26px;
                        position: absolute;
                        top: 24px;
                        width: 0;
                    }
                }

                span {
                    font-size: 14px;
                    padding: 10px;
                }
            }

            iframe {
                height: 100%;
                position: relative;
                width: 100%;
            }
        }
    }

    p {
        line-height: 1.5;
        margin: 4em auto;
        max-width: $content-width-dt;
        text-align: center;

        @media (max-width: $tp-breakpoint-max) {
            max-width: $content-width-tb;
        }

        @media (max-width: $sp-breakpoint-max) {
            margin: 2em 3em 0;
            text-align: left;
        }
    }
}

.landingPageVideo{
    height: 50vw;
    margin-bottom: 3%;
    .video-wrapper .video-inner, .video-wrapper .video-inner .big-video{
        width: 100% !important;
    }
}

.landingPageVideo {
    @media(max-width:760px) {
        height: auto;
    }
    .video-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: hidden;

        .video-inner {
            display: inline-block;
            position: relative;
            background: #000;

            .video-inner-title {
                p {
                    color: white;
                    font-family: 'Didot', serif;
                    font-size: 36px;
                    font-style: normal;
                    margin: auto;
                    position: absolute;
                    text-align: center;
                    top: 10%;
                    left: 50px;
                    right: 50px;
                    width: 100%;
                    z-index: 1;

                    @media (max-width: $sp-breakpoint-max) {
                        color: black;
                        margin-bottom: 30px;
                        margin-left: 10%;
                        position: static;
                        text-align: left;
                        top: 0;
                        width: calc(100% - 50px);
                    }
                }

                @media (max-width: $sp-breakpoint-max) {
                    display: none;
                    &.hideable {
                        display: block;
                    }
                }

                &.hideable {
                    display: none;
                }
            }

            .decoration {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                @media (max-width: $tp-breakpoint-max) {
                    display: none;
                }
            }

            .decoration-right {
                right: 0;

                img {
                    margin-left: 51%;
                }
            }

            .decoration-left {
                left: 0;

                img {
                    margin-left: -57%;
                }
            }

            .small-video {
                height: 366px;
                width: 650px;

                @media (max-width: $tp-breakpoint-max) {
                    height: 270px;
                    width: 480px;
                }

                @media (max-width: $sp-breakpoint-max) {
                    height: 56vw;
                    position: relative;
                    width: 100vw;
                }
            }

            .big-video {
                height: 50vw;
                width: 850px;

                @media (max-width: $tp-breakpoint-max) {
                    height: 344px;
                    width: 610px;
                }

                @media (max-width: $sp-breakpoint-max) {
                    height: 56vw;
                    position: relative;
                    width: 100vw;
                }
            }

            .video-poster-wrapper {
                bottom: 0;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;

                @media (max-width: $sp-breakpoint-max) {
                    position: static;
                }
            }

            h2 {
                color: white;
                margin: 0;
                position: absolute;
                text-align: center;
                top: 10%;
                width: 100%;
                z-index: 1;

                &.hideable {
                    display: none;
                }

                @media (max-width: $sp-breakpoint-max) {
                    color: black;
                    margin-bottom: 30px;
                    margin-left: 10%;
                    position: static;
                    text-align: left;
                    top: 0;
                    width: calc(100% - 50px);

                    &.hideable {
                        display: block;
                    }
                }
            }

            .video-poster {
                height: 100%;
                width: 100%;
            }

            .play-wrapper {
                align-items: center;
                color: #fff;
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 47%;
                width: 100%;

                @media (max-width: $tp-breakpoint-max) {
                    top: 45%;
                }

                @media (max-width: $sp-breakpoint-max) {
                    top: 35%;
                }

                .arrow-play-video {
                    background-color: rgba(0, 0, 0, .13);
                    border: 1px solid white;
                    border-radius: 50%;
                    height: 63px;
                    position: relative;
                    width: 63px;

                    &::before {
                        border-color: transparent;
                        border-left-color: white;
                        border-style: solid;
                        border-width: 7px 14px;
                        box-sizing: content-box;
                        color: white;
                        content: '';
                        height: 0;
                        left: 26px;
                        position: absolute;
                        top: 24px;
                        width: 0;
                    }
                }

                span {
                    font-size: 14px;
                    padding: 10px;
                }
            }

            iframe {
                height: 100%;
                position: relative;
                width: 100%;
            }
        }
    }

    p {
        line-height: 1.5;
        margin: 4em auto;
        max-width: $content-width-dt;
        text-align: center;

        @media (max-width: $tp-breakpoint-max) {
            max-width: $content-width-tb;
        }

        @media (max-width: $sp-breakpoint-max) {
            margin: 2em 3em 0;
            text-align: left;
        }
    }
}