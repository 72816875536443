@import './styles/global-variables';

.accordion {
    display: block;
    width: 100%;

    .accordion-item {
        .item-tab {
            background-color: transparent;
            border: 0;
            cursor: pointer;
            display: flex !important;
            width: 100%;
        }

        .item-content {
            max-height: $max-acc-item-height;
            overflow: hidden;
            transition-duration: .25s;
            transition-property: max-height;
            transition-timing-function: ease-in;

            &.item-hidden {
                max-height: 0;
                transition-timing-function: ease-out;
            }
        }
    }
}

