.landingPageBanner{
    margin-top: -35px;
    display: block;
}
.LandingPageBanner-1 {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: -35px;

    .banner-wrapper{
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
    }

    .banner-wrapper .rightimage{
        height: 37vw;
    }

    .banner-wrapper .bannerText{
        margin: auto;
        background-image: url('https://images.ctfassets.net/xq8n4mwvbz58/1p1HptPhZYv0LBiRmZAXbO/2384d8cf2cc2bbeb33e8bf7446b32055/Rectangle_118.jpg');
        z-index: 2;
        padding-left: 13%;
        padding-right: 1%;
        margin-right: -17%;
    }

    .bannerText h2{
        font-size: 50px;
        line-height: 72px;
        font-weight: 700;
        font-family: 'Didot';
        margin-block-end: 0em;
        margin-block-start: 0.5em;
    }

    .bannerText .text1{
        font-family: Didot;
        font-size: 50px!important;
        font-weight: 400;
        line-height: 72px;
        font-style: italic;
        margin-block-end: 0em;
        margin-block-start: 0em;
    }

    .bannerText p{
        font-size: 22px;
        line-height: 35px;
        font-weight: 400;
        font-family: 'Avenir Next LT Pro';
    }
    .rightimage {

        img {
            height: 37vw;
        }
    }
}

@media (max-width:921px) {
    .LandingPageBanner-1 {
        position: relative;
        margin-bottom: 50px;
        margin-top: -35px !important;
        height: auto!important;
        .bannerText p{
            font-size: 14px!important;
            font-weight: 500;
        }
        .bannerText .text1{
            font-size: 25px!important;
        }
        .bannerText h2{
            font-size: 30px!important;
        }
        .banner-wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        .banner-wrapper .rightimage{
            height: auto !important;
            margin-top: -185px;
        }

        .banner-wrapper .rightimage img{
            height: 139vw!important;
            width: 100vw;
            object-fit: contain;
        }

        .banner-wrapper .bannerText{
            margin-top: -401px;
            width: 80%;
            margin-left: auto!important;
            margin-right: auto!important;
            padding-left: 20px !important;
            padding-right: 15px !important;
            padding-top: 10px !important;
            padding-bottom: 10px !important;
        }
    }
}

@media(max-width:720px) {
    .LandingPageBanner-1 {
        margin-top: -59px !important;
        height: auto!important;
        .bannerText p{
            font-size: 14px!important;
            line-height: 1.5;
            font-weight: 500;
        }
        .bannerText h2{
            margin-block-end: 0.83em!important;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-size: 30px!important;
            line-height: normal!important;
        }
        .bannerText .text1{
            font-size: 25px!important;
            line-height: 1.5!important;
            font-weight: 500!important;
            margin-block-start: 1em!important;
            margin-block-end: 1em!important;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
        }
        .banner-wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        .banner-wrapper .rightimage{
            height: auto !important;
            margin-top: 0px;
        }

        .banner-wrapper .rightimage img{
            height: 139vw;
            width: 100vw;
        }

        .banner-wrapper .bannerText{
            margin-top: -171px;
            width: 80%;
            margin-left: auto;
            margin-right: auto!important;
            padding-left: 20px !important;
            padding-right: 15px !important;
            padding-top: 10px !important;
            padding-bottom: 10px !important;
        }

        .rightimage {
            img {
                object-fit: contain;
            }
        }

        .bannerText {
            .column-text {
                padding: 10px 20px;
            }

            .text1 {
                margin-top: -20px;
            }
        }
    }
}

@media(max-width: 1280px){
    .LandingPageBanner-1{
        margin-top: 50px;
        height: 35vw;
    }
    .LandingPageBanner-1 .banner-wrapper .bannerText{
        padding-left: 33%;
        padding-right: 2%;
    }
    .LandingPageBanner-1 .banner-wrapper .rightimage{
        height: 35vw;
    }
    .LandingPageBanner-1 .rightimage img{
        height: 35vw;
    }
    .LandingPageBanner-1 .bannerText p{
        font-size: 22px;
        margin-block-start: 0em;
    }

    .LandingPageBanner-1 .bannerText .text1{
        font-size: 50px;
    }
}

@media only screen and (max-width: 1230px) and (min-width: 1100px){
    .LandingPageBanner-1{
        margin-top: 50px;
        height: 44vw!important;
    }
    .LandingPageBanner-1 .banner-wrapper .bannerText{
        padding-left: 3%!important;
        padding-right: 2%!important;
        margin-right: -20%!important;
    }
    .LandingPageBanner-1 .banner-wrapper .rightimage{
        height: 44vw!important;
    }
    .LandingPageBanner-1 .rightimage img{
        height: 44vw!important;
    }
    .LandingPageBanner-1 .bannerText p{
        font-size: 22px;
        margin-block-start: 0em;
    }

    .LandingPageBanner-1 .bannerText .text1{
        font-size: 50px;
    }
}

@media only screen and (max-width: 1100px) and (min-width: 922px){
    .LandingPageBanner-1{
        margin-top: 50px;
        height: 35vw!important;
    }
    .LandingPageBanner-1 .banner-wrapper .bannerText{
        padding-left: 3%!important;
        padding-right: 0%!important;
        margin-right: -13%!important;
    }
    .LandingPageBanner-1 .banner-wrapper .rightimage{
        height: 35vw!important;
    }
    .LandingPageBanner-1 .rightimage img{
        height: 35vw!important;
    }
    .LandingPageBanner-1 .bannerText p{
        font-size: 18px!important;
        margin-block-start: 0em;
        line-height: 30px!important;
    }

    .LandingPageBanner-1 .bannerText .text1{
        font-size: 40px!important;
        line-height: 50px!important;
    }

    .LandingPageBanner-1 .bannerText h2{
        font-size: 40px!important;
        line-height: 50px!important;
    }
}

@media(max-width: 1500px){
    .LandingPageBanner-1{
        height: 35vw;
    }
    .LandingPageBanner-1 .banner-wrapper .bannerText{
        padding-left: 1%;
        padding-right: 13%;
        margin-right: -13%;
    }
    .LandingPageBanner-1 .banner-wrapper .rightimage{
        height: 35vw;
    }
    .LandingPageBanner-1 .rightimage img{
        height: 35vw;
    }
    .LandingPageBanner-1 .bannerText h2{
        font-size: 50px;
        line-height: 72px;
        font-weight: 700;
        font-family: 'Didot';
        margin-block-end: 0em;
        margin-block-start: 0.5em;
    }

    .LandingPageBanner-1 .bannerText p{
        font-size: 22px;
    }

    .LandingPageBanner-1 .bannerText .text1{
        font-family: Didot;
        font-size: 50px;
        font-weight: 400;
        line-height: 72px;
        font-style: italic;
        margin-block-end: 0em;
        margin-block-start: 0em;
    }
}

@media(max-width: 1366px){
    .LandingPageBanner-1{
        margin-top: 3%;
        height: 36vw;
    }
    .LandingPageBanner-1 .banner-wrapper .bannerText{
        padding-left: 5%;
        padding-right: 1%;
        margin-right: -17%;
    }
    .LandingPageBanner-1 .banner-wrapper .rightimage{
        height: 36vw;
    }
    .LandingPageBanner-1 .rightimage img{
        height: 36vw;
    }

    .LandingPageBanner-1 .bannerText h2{
        font-size: 50px;
        line-height: 72px;
        font-weight: 700;
        font-family: 'Didot';
        margin-block-end: 0em;
        margin-block-start: 0.5em;
    }

    .LandingPageBanner-1 .bannerText p{
        font-size: 22px;
    }

    .LandingPageBanner-1 .bannerText .text1{
        font-family: Didot;
        font-size: 50px;
        font-weight: 400;
        line-height: 72px;
        font-style: italic;
        margin-block-end: 0em;
        margin-block-start: 0em;
    }
}
