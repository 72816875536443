@import './styles/global-variables';

.rating-stars {
    align-items: center;
    color: $dark-green;
    font-size: 25px;
    position: relative;
    z-index: 1;

    .star {
        display: inline-block;
        position: relative;
        width: 25px;

        .empty {
            left: 0;
            position: absolute;
            top: -22px;
        }

        .full {
            color: $dark-green;
            display: none;
            left: 0;
            overflow: hidden;
            position: absolute;
            top: -22px;
        }

        &.on {
            .full {
                display: block;
            }
        }
    }

    .kbd-picker {
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;

        .kbd-star {
            display: inline-block;
            font-size: 0;
            height: 100%;
            line-height: 0;
            margin-right: 2px;

            &:last-child {
                margin-right: -1px;
            }

            &:focus {
                @include focusable-item;
            }
        }
    }

    .rating-numbers {
        color: $dark-gray;
        display: inline-block;
        font-size: 16px;
        margin-left: 10px;
    }
}
