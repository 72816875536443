@import './styles/global-variables';

.peta-block-container {
    background-position: calc(50% - 740px) center, calc(50% + 740px) center;
    background-repeat: no-repeat, no-repeat, repeat;
    box-shadow: $paper-box-shadow;
    contain-intrinsic-size: 320px;
    content-visibility: auto;
    margin-bottom: 70px;

    @media (max-width: $tp-breakpoint-max) {
        background-position: right 24px;
        background-size: 0, 220px, auto;
        margin-bottom: 55px;
        padding-bottom: 50px;
    }

    @media (max-width: $sp-breakpoint-max) {
        background-position: right 5px;
        background-size: 0, 100px, auto;
        margin-bottom: 40px;
        padding-bottom: 50px;
    }

    @media (min-width: 540px) and (max-width: $sp-breakpoint-max) {
        height: 213px;
    }

    .peta-info-box {
        margin: 0 auto;
        max-width: 1080px;
        padding: 20px 120px;

        @media (min-width: $dt-breakpoint-min) {
            width: calc(100% - 68px);
        }

        @media (max-width: $tp-breakpoint-max) {
            padding: 0 80px;
        }

        @media (max-width: $sp-breakpoint-max) {
            padding: 0 30px;
        }

        @media (min-width: 540px) and (max-width: $sp-breakpoint-max) {
            width: calc(100% - 30px);
        }

        .peta-title {
            h2 {
                margin-top: 0;
                max-width: 75%;
                padding-top: 42px;

                @media (max-width: $sp-breakpoint-max) {
                    max-width: none;
                    padding-top: 22px;
                }
            }
        }

        .peta-icons {
            display: flex;
            width: 65%;

            @media (max-width: $tp-breakpoint-max) {
                width: 90%;
            }

            @media (max-width: $sp-breakpoint-max) {
                flex-wrap: wrap;
            }

            @media (min-width: 540px) and (max-width: $sp-breakpoint-max) {
                width: 65%;
            }

            a {
                width: 33.3333%;
            }
        }
    }
}
