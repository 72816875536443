@import './styles/global-variables';

.block-wrapper-with-image {
    @include block-wrapper-common;

    background-position: calc(50% - 740px) 90%, calc(50% + 740px) 90%;
    contain-intrinsic-size: 800px;
    content-visibility: auto;
    margin-top: -33px;
    position: relative;

    @media only screen and (min-width:1981px) and (max-width:7680px) { 
        margin-top: calc(100vh - 20vw);
    }

    .wrapper-top-image {
        background-position: center;
        background-size: cover;
        height: 320px;
        margin: auto;
        position: relative;
        width: 100%;

        @media (max-width: $tp-breakpoint-max) {
            margin-top: 73px;
        }

        @media (max-width: $sp-breakpoint-max) {
            background-repeat: no-repeat;
            background-size: contain;
            height: 141px;
            margin-top: 42px;
        }

        @media (min-width: $xl-breakpoint-min) {
            height: 22.22222vw;
        }

        .top-logos {
            bottom: 155px;
            left: 50%;
            margin: auto;
            padding: 0 120px;
            position: absolute;
            transform: translateX(-50%);
            width: 1080px;

            @media (max-width: 1180px) {
                width: calc(100% - 68px);
            }

            @media (max-width: $tp-breakpoint-max) {
                bottom: 190px;
                padding: 0 45px;
            }

            @media (max-width: $sp-breakpoint-max) {
                bottom: 104px;
                padding: 0 30px;
                position: relative;
                top: 30px;
                width: calc(100% - 30px);
            }

            @media (min-width: $xl-breakpoint-min) {
                bottom: calc(8.3333333vw + 35px);
            }

            img {
                margin-right: 25px;

                @media (max-width: $tp-breakpoint-max) {
                    width: 90px;
                }
                @media (max-width: $sp-breakpoint-max) {
                    width: 75px;
                }
            }
        }
    }
}
