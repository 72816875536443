@import './styles/global-variables';

.quiz {
    background-position: left center, right center;
    background-repeat: no-repeat, no-repeat;
    margin-bottom: 70px;
    width: 100%;

    iframe {
        border: 1px solid #707070;
        display: block;
        margin: 50px auto 0;
        min-height: 800px;
        width: 1080px;

        @media (max-width: $tp-breakpoint-max) {
            min-height: 600px;
            width: 85%;
        }
    }
}
