@import './styles/global-variables';

$gray-golden-light: #ece7e1;

footer {
    background-color: $light-gray;
    border-top: 1px $gray-golden-light solid;
    contain-intrinsic-size: 300px;
    width: 100%;

    @media (max-width: $tp-breakpoint-max) {
        border-top: 0;
    }

    .footer-inner {
        color: $golden;
        font-size: 14px;
        line-height: 22px;
        margin: 0 auto;
        max-width: 1200px;
        width: 100%;

        @media (max-width: 1280px) {
            max-width: 924px;
        }

        @media (max-width: $tp-breakpoint-max) {
            max-width: 610px;
        }

        @media (max-width: $sp-breakpoint-max) {
            max-width: 85vw;
        }

        img {
            vertical-align: middle;
        }

        .ad-choices {
            img {
                margin-left: 4px;
            }
        }

        .cookieConsent{
            background-color: white;
            border: 1px solid rgb(0, 61, 165);
            border-radius: 8px;
            bottom: 0px;
            color: rgb(19, 36, 104);
            cursor: pointer;
            display: block;
            font-family: Avenir, sans-serif;
            padding: 8px;
            position: fixed;
            right: 0px;
            z-index: 999;
        }

        .social-smartlabel {
            padding-bottom: 32px;
            padding-top: 56px;

            @media (max-width: $tp-breakpoint-max) {
                border-bottom: 1px $gray-golden-light solid;
                padding-top: 35px;
            }

            @media (max-width: $sp-breakpoint-max) {
                text-align: center;
            }

            .social-block {
                display: inline-block;
                width: calc(100% - 110px);

                @media (max-width: $sp-breakpoint-max) {
                    display: block;
                    width: 100%;

                    span {
                        display: inline-block;
                        margin-bottom: 28px;
                        margin-top: 6px;
                    }
                }

                div {
                    display: inline-block;

                    .social-links {
                        display: flex;
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        @media (max-width: $sp-breakpoint-max) {
                            display: inline-flex;

                            li {
                                margin: 0 25px;
                            }
                        }
                    }

                    @media (max-width: $sp-breakpoint-max) {
                        display: block;
                    }

                    img {
                        margin-left: 10px;

                        @media (max-width: $tp-breakpoint-max) {
                            margin-left: 45px;
                            transform: none;
                        }
                    }

                    a {
                        &:first-child {

                            img {
                                @media (max-width: $sp-breakpoint-max) {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }

            .smartlabel {
                @media (max-width: $tp-breakpoint-max) {
                    transform: none;
                }

                @media (max-width: $sp-breakpoint-max) {
                    padding-bottom: 18px;
                    padding-top: 74px;
                }
            }
        }

        .footer-links {
            padding-bottom: 31px;
            padding-top: 20px;
            text-align: center;

            @media (min-width: $dt-breakpoint-min) {
                display: flex;
                justify-content: center;
            }

            @media (max-width: $tp-breakpoint-max) {
                column-count: 2;
                padding-bottom: 24px;
                text-align: left;
            }

            @media (max-width: $sp-breakpoint-max) {
                column-count: 1;
                text-align: center;
            }

            span {
                border-right: 1px $gray-golden-light solid;
                padding: 6px 54px;

                @media (max-width: 1280px) {
                    padding: 6px 26px;
                }

                @media (max-width: $tp-breakpoint-max) {
                    border: 0;
                    padding: 11px 0;
                }
            }

            a {
                border-right: 1px $gray-golden-light solid;
                color: $golden;
                padding: 6px 51px;

                @media (max-width: 1280px) {
                    padding: 6px 24px;
                }

                @media (max-width: $tp-breakpoint-max) {
                    border-right: 0;
                    display: block;
                    padding: 11px 0;
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    border-right: 0;
                    padding-right: 0;
                }
            }
        }

        .copyright {
            border-top: 1px $gray-golden-light solid;
            padding-bottom: 14px;
            padding-top: 10px;
            text-align: center;

            @media (max-width: $tp-breakpoint-max) {
                text-align: left;
            }

            @media (max-width: $sp-breakpoint-max) {
                padding-bottom: 57px;
                padding-top: 27px;
                text-align: center;
            }

            p {
                display: inline-block;
                margin-right: 50px;

                @media (max-width: $tp-breakpoint-max) {
                    font-size: 12px;
                    width: calc(100% - 20px);
                }

                @media (max-width: $sp-breakpoint-max) {
                    display: block;
                    padding-bottom: 22px;
                }
            }

            .BBBimg {
                transform: scale(.85, .85);

                @media (max-width: $tp-breakpoint-max) {
                    padding-left: 0;
                    transform: none;
                }
            }
        }
    }
}
