@import "./styles/global-variables";

.slides-collection-container {
    margin: 0 auto 40px;
    overflow: hidden;
    padding: 0 0 50px;
    text-align: center;

    .Slide img {
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }

    .slick-slider {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
    }
    .slick-slider .slick-list {
        flex-basis: 100%;
        margin-bottom: 30px;
        width: 100%;
    }

    .slick-next,
    .slick-prev {
        @include circled-arrow;
        position: relative;
        top: -8px;
        order: 4;
    }
    .slick-prev {
        order: 2;
        transform: rotate(180deg);
    }
    .slick-next {
        order: 4;
    }
    .slick-dots {
        bottom: -25px;
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        text-align: center;
        width: 100%;
        margin: 0 20px;
        order: 3;
        position: static;
        width: auto;
    }
    .slick-dots li.slick-active button {
        background: #000;
        border-radius: 10px;
    }

    .slick-dots li button {
        background-color: #c5c5c5;
        border-radius: 10px;
        height: 14px;
        width: 14px;
    }

    @media (max-width: $sp-breakpoint-max) {
        .slick-dots li {
            margin: 0 !important;
        }
        .video-poster-wrapper {
            width: 80% !important;
        }
        .slick-slider .slick-list {
            margin-bottom: 0;
        }
        .Slide {
            width: 80% !important;
            margin-bottom: 30px;
        }
    }

    .video-poster-wrapper {
        position: relative;
        right: 0;
        top: 0;
    }

    .video-poster {
        height: 100%;
        width: 100%;
    }

    .play-wrapper {
        align-items: center;
        color: #fff;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 42%;
        width: 100%;

        @media (max-width: $tp-breakpoint-max) {
            top: 45%;
        }

        .arrow-play-video {
            background-color: rgba(0, 0, 0, 0.13);
            border: 1px solid white;
            border-radius: 50%;
            height: 63px;
            position: relative;
            width: 63px;

            &::before {
                border-color: transparent;
                border-left-color: white;
                border-style: solid;
                border-width: 7px 14px;
                box-sizing: content-box;
                color: white;
                content: "";
                height: 0;
                left: 26px;
                position: absolute;
                top: 24px;
                width: 0;
            }
        }

        span {
            font-size: 14px;
            padding: 10px;
        }
    }

    iframe {
        height: 100%;
        position: relative;
        width: 100%;
    }
}

.overlay {
    position: fixed;
    top: -3px;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: hsla(0, 0%, 100%, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    .video-container {
        position: relative;
        padding: 32px;
        width: 100%;
        max-width: 90%;
        background-color: #fff;
        box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.5);

        iframe {
            height: 42vw;
        }

        @media (max-width: $sp-breakpoint-max) {
            iframe {
                height: 100vw !important;
            }
        }

        button {
            position: absolute;
            top: 1px;
            right: 6px;
            background: transparent;
            border: none;
            font-size: 26px;
            font-weight: 900;
        }
    }
}
