@import './styles/global-variables';
$h: 48.6vw;
$left-dt: 12.9vw;

.ingredients-carousel-container {
    background-position: calc(50% - 740px) center, calc(50% + 740px) center;
    background-repeat: no-repeat, no-repeat;
    contain-intrinsic-size: 450px;
    content-visibility: auto;
    padding-bottom: 40px;
    width: 100%;

    @media (max-width: $tp-breakpoint-max) {
        background-size: 0, 0;
    }

    .ingredients-carousel {
        margin: 0 auto;
        max-width: 1120px;
        position: relative;

        .slick-slider {
            padding-top: 120px;

            @media (max-width: $sp-breakpoint-max) {
                overflow: hidden;
            }

            .slick-prev,
            .slick-next {
                @include circled-arrow;

                color: black;
                flex-wrap: wrap;
                position: absolute;
                top: 34px;
                transform: none;
            }

            .slick-prev {
                left: 17px;
                transform: rotate(180deg);

                @media (min-width: $dt-breakpoint-min) {
                    left: 465px;
                }

                @media (max-width: $tp-breakpoint-max) {
                    left: 25px;
                    top: 50px;
                }
            }

            .slick-next {
                left: 129px;

                @media (min-width: $dt-breakpoint-min) {
                    left: 621px;
                }

                @media (max-width: $tp-breakpoint-max) {
                    left: 141px;
                    top: 50px;
                }
            }
        }

        .slick-initialized {
            .slick-list {
                @media (max-width: $sp-breakpoint-max) {
                    margin: 0 -15px;
                }
            }

            .slick-slide {
                display: flex;
                justify-content: center;

                .slide {
                    height: 241px;
                    position: relative;
                    width: 176px !important;

                    &:focus {
                        outline: 1px dotted $text-gray;
                    }

                    @media (max-width: $sp-breakpoint-max) {
                        height: 398px;
                        width: 281px !important;
                    }

                    @media (max-width: $xs-breakpoint-max) {
                        height: 350px;
                        width: 220px !important;
                    }
                }

                a {
                    color: black;
                    display: block;
                    padding: 10px;
                }
            }
        }

        .image-slide {
            background-size: cover;
            height: 100% !important;
            width: 100%;
        }

        .text-slide {
            position: relative;
            text-align: center;
            z-index: 2;

            &:focus {
                outline: 1px dotted $text-gray;
            }

            p {
                font-size: 18px;
                margin: 20px auto;

                @media (max-width: $tp-breakpoint-max) {
                    font-size: 16px;
                }

                @media (max-width: $sp-breakpoint-max) {
                    font-size: 19px;
                    width: 136px;
                }
            }
        }
    }
}
