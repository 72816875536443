@import './styles/global-variables';
.product-header {
    margin-bottom: 50px;
    margin-top: -50px;
    position: relative;

    @media (max-width: $tp-breakpoint-max) {
        margin-bottom: 80px;
        margin-top: 20px;
    }

    @media (max-width: $sp-breakpoint-max) {
        margin-bottom: 80px;
        margin-top: 5px;
    }

    .content {
        display: flex;
        margin: 0 auto;
        width: 100vw;
        max-width: 1400px;

        @media (max-width: $sp-breakpoint-max) {
            margin: 45px auto;
        }

        @media (max-width: $tp2-breakpoint-max) {
            flex-direction: column;
            width: 100%;
        }

        .product-images {
            display: flex;
            flex-direction: row-reverse;
            margin-top: 40px;
            padding-right: 41px;
            width: 58%;

            @media (max-width: $tp2-breakpoint-max) {
                display: block;
            }

            @media (max-width: $tp-breakpoint-max) {
                display: block;
                margin: auto;
                padding-right: 0;
                width: 100%;
            }

            @media (max-width: $sp-breakpoint-max) {
                display: block;
                width: 100%;
            }
        }

        .product-info {
            width: 40%;

            @media (max-width: $tp2-breakpoint-max) {
                margin: 0 auto;
                width: 90%;
            }

            @media (max-width: $tp-breakpoint-max) {
                margin: -64px auto;
                padding: 0 74px;
                width: 100%;
            }

            @media (max-width: $sp-breakpoint-max) {
                padding: 0 45px;
            }

            .product-heading {
                .desktop-view {
                    p {
                        font-family: Didot, serif;
                        font-size: 46px;
                        font-style: italic;
                        font-weight: normal;
                        margin-bottom: 10px;
                        margin-top: 90px;
                    }
                }
            }

            .description {
                line-height: 1.45;

                h2,
                h3,
                h4,
                h5 {
                    font-family: 'Avenir', sans-serif;
                    font-size: 19px;
                    font-weight: normal;
                    margin-bottom: 0;
                    margin-top: 20px;

                    @media (max-width: $tp-breakpoint-max) {
                        font-size: 16px;
                        margin-top: 65px;
                    }

                    & + p {
                        margin-top: 5px;
                    }
                }

                p,
                ul {
                    color: $text-gray;
                }
                p a{text-decoration: underline;}
            }
        }

        .product-heading {
            @media (max-width: $tp2-breakpoint-max) {
                display: none;
            }

            @media (max-width: $tp-breakpoint-max) {
                display: none;
            }

            h1,
            h2 {
                font-size: 36px;
                font-weight: normal;
                margin-bottom: 10px;
                margin-top: 30px;

                @media (max-width: $sp-breakpoint-max) {
                    font-size: 28px;
                    margin-top: 10px;
                }
            }

            &.mobile {
                display: none;

                @media (max-width: $tp2-breakpoint-max) {
                    display: block;
                    margin: auto;
                    padding: 0 78px;
                    width: 100%;
                }

                @media (max-width: $sp-breakpoint-max) {
                    margin-top: 29px;
                    padding: 0 32px;

                    p {
                        font-family: Didot, serif;
                        font-size: 26px;
                        font-style: italic;
                        font-weight: normal;
                        margin-bottom: 10px;

                        @media (max-width: $tp-breakpoint-max) {
                            font-size: 12px;
                            font-style: normal;
                        }
                    }
                }
            }

            .product-tag {
                background: transparent linear-gradient(270deg, #a87c33 0%, #ad833b 4%, #f4e1a7 54%, #6f4617 100%) 0% 0% no-repeat padding-box;
                border-radius: 4px;
                display: inline-block;
                font-style: normal;
                font-weight: 900;
                margin: 0;
                opacity: 0.8;
                padding: 9px 28px;
            }

            .actions {
                display: flex;

                @media (max-width: $sp-breakpoint-max) {
                    flex-wrap: wrap;
                }

                .rating-numbers {
                    font-size: 1rem;
                    margin-top: 5px;
                }

                .rating-stars {
                    margin-right: 15px;
                }

                .share-button {
                    font-size: 1rem;
                    margin-top: 10px;
                }

                .actions-reviews {
                    color: $golden;
                    font-size: 12px;
                    margin-left: 20px;
                    margin-top: 13px;
                    text-decoration: underline;
                }

                .actions-reviews-btn {
                    background: transparent;
                    border: 0;
                    color: $golden;
                    cursor: pointer;
                    font-size: 12px;
                    margin-left: 20px;
                    margin-top: 7px;
                    text-decoration: underline;

                    &:focus {
                        @include focusable-item;
                    }

                    @media (max-width: $sp-breakpoint-max) {
                        margin-left: 0;
                    }
                }
            }
        }

        .ingredients-smartlabel {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            a {
                color: $dark-gray;
                display: inline-block;
                margin-top: 20px;

                &.internal-link {
                    @include golden-underline;

                    padding: 0 0 5px;
                }
            }

            @media (max-width: $sp-breakpoint-max) {
                flex-direction: column;
            }
        }

        .retailers {
            display: flex;
            margin-top: 35px;

            @media (max-width: $sp-breakpoint-max) {
                flex-direction: column-reverse;
            }

            > * {
                margin-right: 20px;

                @media (max-width: $tp-breakpoint-max) {
                    margin: 10px;
                }

                @media (max-width: $sp-breakpoint-max) {
                    margin: 0;
                }
            }
        }
    }

    .bg-decoration {
        position: absolute;
        right: 0;
        top: -30px;
        z-index: -1;

        @media (max-width: $tp-breakpoint-max) {
            display: none;
        }
    }

    .keep-scrolling {
        color: $golden;
        font-size: 0.75rem;
        position: absolute;
        right: 5%;
        top: 40%;
        transform: rotate(-90deg);

        &::before {
            content: '\2190';
            margin-right: 8px;
        }

        @media (max-width: $tp-breakpoint-max) {
            display: none;
        }
    }
}

.slick-slide {
    span {
        height: auto !important;
    }
}
