.landingPageCardContainer{
    background-image: url('https://images.ctfassets.net/xq8n4mwvbz58/ktCgcakFXXJPJabUOphvO/9d9ae6a3201c1bcf3cb85f66e2b34edd/image_150.jpg'), url('https://images.ctfassets.net/xq8n4mwvbz58/dvVF234cw0YUJJpMjimBo/059c3904560bdd7d281eb5136b7e877c/Background_image.jpg');
    background-repeat: no-repeat;
    background-position: calc(50% - 723px),calc(50% + 740px);
    contain-intrinsic-size: 800px;
    content-visibility: auto;
}
.landingPageCardsCarousel {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
}

.landingPageCardsCarouselmobile {
    width: 100%;

    @media (max-width:700px){
        background-image: url('https://images.ctfassets.net/xq8n4mwvbz58/dvVF234cw0YUJJpMjimBo/059c3904560bdd7d281eb5136b7e877c/Background_image.jpg');
        background-repeat: no-repeat;
        background-position: calc(100% - -92px) calc(100% - 100px);
        padding-top: 40px;
    }

    .card {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
        // display: flex;
        // flex-direction: column;

        .card-1 {

            background-image: url('https://images.ctfassets.net/xq8n4mwvbz58/7v4KNdOawnMc04LeHhQ31q/9d302a588f00ef3d41c7316793e2a7be/Image_placeholder.png?h=250');
            z-index: -9999;

            img {
                margin-left: 60px;
                height: 200px;
                width: 200px;
            }
        }



        .column-text {
            background-color: #173F36;
            line-height: 1px;
            padding-left: 10px;
            color: #F5F6F1;
            font-family: 'Didot';

            p {
                min-height: 50px;
                width: 200px;
            }

            h5 {
                margin-top: 0px;
                padding: 20px 0px 0px 0px;
            }

            h3 {
                font-size: 20px;
                margin-bottom: 35px;
            }

            .description-text {
                display: block;
                margin-left: -10px;

                .viewProductButton {
                    width: 95%;
                    margin-top: -20px;
                    margin-bottom: 20px;
                    margin-left: 10px;
                    padding: 10px 20px;
                    outline: none;
                    border: none;
                    font-weight: 400;
                    background-image: linear-gradient(to right, #C49543, #D5B965, #C49241, #D5B965, #C49543);
                }

                .column-text {
                    background: none;

                    p {}
                }
            }
        }
    }

    .slideChanger {
        width: 50%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        margin-bottom: 30px;

        ul {
            display: flex;
            list-style: none;
            width: 50%;
            margin-left: -35px;
            justify-content: space-between;

            button {
                border: none;
                outline: none;
            }
        }
    }
}

.landingPageCardContainerTablet{
    background-image: url('https://images.ctfassets.net/xq8n4mwvbz58/ktCgcakFXXJPJabUOphvO/9d9ae6a3201c1bcf3cb85f66e2b34edd/image_150.jpg'), url('https://images.ctfassets.net/xq8n4mwvbz58/dvVF234cw0YUJJpMjimBo/059c3904560bdd7d281eb5136b7e877c/Background_image.jpg');
    background-repeat: no-repeat;
    background-position: calc(50% - 281px),calc(50% + 302px);
}

.landingPageCardsCarouselTablet {
    width: 60%;
    margin-right: auto;
    margin-left: auto;

    .card {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;

        .card-1 {

            background-image: url('https://images.ctfassets.net/xq8n4mwvbz58/7v4KNdOawnMc04LeHhQ31q/9d302a588f00ef3d41c7316793e2a7be/Image_placeholder.png?h=250');
            z-index: -9999;

            img {
                margin-left: 60px;
                height: 200px;
                width: 200px;
            }
        }



        .column-text {
            background-color: #173F36;
            line-height: 1px;
            padding-left: 10px;
            color: #F5F6F1;
            font-family: 'Didot';

            p {
                min-height: 50px;
                width: 200px;
            }

            h5 {
                margin-top: 0px;
                padding: 20px 0px 0px 0px;
            }

            h3 {
                font-size: 20px;
                margin-bottom: 35px;
            }

            .description-text {
                display: block;
                margin-left: -10px;

                .viewProductButton {
                    width: 95%;
                    margin-top: -20px;
                    margin-bottom: 20px;
                    margin-left: 10px;
                    padding: 10px 20px;
                    outline: none;
                    border: none;
                    font-weight: 400;
                    background-image: linear-gradient(to right, #C49543, #D5B965, #C49241, #D5B965, #C49543);
                }

                .column-text {
                    background: none;

                    p {}
                }
            }
        }
    }

    .slideChanger {
        width: 50%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        margin-bottom: 30px;

        ul {
            display: flex;
            list-style: none;
            width: 50%;
            margin-left: -35px;
            justify-content: space-between;

            button {
                border: none;
                outline: none;
            }
        }
    }
}