@import './styles/global-variables';

.percent {
    color: #000;
    margin: 0 auto 70px;
    max-width: 1080px;
    padding: 20px 0 0 74px;
    width: calc(100% - 68px);

    @media (max-width: $tp-breakpoint-max) {
        margin: 55px auto;
        padding: 0;
    }

    @media (max-width: $sp-breakpoint-max) {
        margin: 42px auto;
        width: 100%;
    }

    &::after {
        clear: both;
        content: '';
        display: table;
    }

    .ornament {
        display: inline-block;
        max-width: 372px;
        vertical-align: top;
        width: 31.75%;

        img {
            width: 100%;
        }

        @media (max-width: $tp-breakpoint-max) {
            display: block;
            margin: 0 auto 60px;
            text-align: center;
            width: 314px;
        }

        @media (max-width: $sp-breakpoint-max) {
            margin-bottom: 55px;
            max-width: 233px;
            width: 100%;
        }
    }

    .text-wrapper {
        float: right;
        width: 60%;

        @media (max-width: $tp-breakpoint-max) {
            float: none;
            padding: 0 45px;
            width: 100%;
        }

        @media (max-width: $sp-breakpoint-max) {
            padding: 0 60px;
        }

        &::after {
            clear: both;
            content: '';
            display: table;
        }
    }

    .left-list,
    .right-list {
        display: inline-block;
        font-family: 'Avenir', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 2rem;
        max-width: 290px;
        vertical-align: top;
        width: 50%;

        @media (max-width: $tp-breakpoint-max) {
            max-width: inherit;
        }

        @media (max-width: $sp-breakpoint-max) {
            display: block;
            margin: 0 auto;
            max-width: 254px;
            text-align: center;
            width: 100%;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                &:not(:last-of-type) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .left-list {
        padding-right: 8.3333%;
        position: relative;

        &::after {
            background-color: #c5c5c5;
            bottom: 0;
            content: '';
            display: block;
            height: calc(100% + 20px);
            position: absolute;
            right: 0;
            width: 2px;
        }

        @media (max-width: $tp-breakpoint-max) {
            padding-right: 4.0983%;
        }

        @media (max-width: $sp-breakpoint-max) {
            padding: 0 0 50px;
            vertical-align: auto;

            &::after {
                height: 2px;
                left: -45px;
                width: calc(100% + 90px);
            }
        }
    }

    .right-list {
        padding-left: 8.3333%;

        @media (max-width: $tp-breakpoint-max) {
            padding-left: 6.5573%;
        }

        @media (max-width: $sp-breakpoint-max) {
            padding: 50px 0 0;
            vertical-align: auto;
        }
    }
}
