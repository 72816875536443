@import './styles/global-variables';

.header-carousel {
    $h: 48.6vw;
    $left-dt: 6.9vw;
    $shine: #ecd38b;
    $lighter: #94602f;

    margin-bottom: 64px;
    margin-top: -103px;

    @media (max-width: 1390px) {
        margin-top: -202px;
    }

    @media (min-width: $dt-breakpoint-min) {
        max-height: 720px;
    }

    .slick-initialized {
        @media (min-width: $dt-breakpoint-min) {
            max-height: 720px;
        }

        @media (max-width: $tp-breakpoint-max) {
            border: 0;
        }

        .slick-list {
            height: 100%;

            @media (max-width: $sp-breakpoint-max) {
                overflow: visible;
            }

            .slick-track {
                position: absolute;
            }
        }
    }

    @media (max-width: $tp-breakpoint-max) {
        height: auto;
        margin-bottom: 10px;
        margin-top: -85px;
        text-align: center;
    }

    @media (max-width: $sp-breakpoint-max) {
        margin-bottom: 88px;
        margin-top: -65px;
    }

    .slide-all {
        border-bottom: 4px solid #e8e8e8;
        display: flex !important;
        flex-direction: row-reverse;
        width: 100%;

        @media (min-width: $dt-breakpoint-min) {
            max-height: 720px;
        }

        @media (max-width: $tp-breakpoint-max) {
            border: 0;
            flex-direction: column;
            height: auto;
        }

        @media (width: 1097.14px) {
            max-height: 460px !important;
        }

        @media (width: 1280px) {
            max-height: 560px !important;
        }

        @media (width: 1536px) {
            max-height: 655px !important;
        }

        @media (width: 1745.45px) {
            max-height: 695px !important;
        }

        @media (width: 2133.33px) {
            max-height: 800px !important;
        }

        @media (width: 2560px) {
            max-height: 985px !important;
        }

        @media (width: 2880px) {
            max-height: 1070px !important;
        }

        @media (width: 3840px) {
            max-height: 1430px !important;
        }

        @media (width: 5760px) {
            max-height: 2150px !important;
        }

        @media (width: 7680px) {
            max-height: 2800px !important;
        }

        @media only screen and (min-width:1981px) and (max-width:7680px) {
            max-height: 2250px;
        }
    }

    .slide-image {
        width: 40%;

        @media (max-width: $tp2-breakpoint-max) {
            width: 52%;
        }

        @media (max-width: $tp-breakpoint-max) {
            height: auto;
            position: static;
            width: 100%;
        }

        @media (min-width: 7680px) {
            display: flex;
            align-items: center;
        }

        img {
            border-image: linear-gradient(to bottom, $lighter, $shine, $lighter) 1 100%;
            border-left: 10px solid;
            border-right: 0;
            height: 100%;
            width: 100%;

            @media (max-width: $tp-breakpoint-max) {
                border: 0;
            }

            @media (max-width: $sp-breakpoint-max) {
                height: 328px;
                margin: 0 auto;
                width: 315px;
            }

            @media only screen and (min-width:120em) and (max-width:480em) {
                height: auto;
            }
        }
    }

    .slide-texts {
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
        background-image: url('https://images.ctfassets.net/a8l3ylu84syn/30gWnstSMn8bQIQPyEiLxz/5120b80278f4a89ae26a2d5f633565a3/block-bg.jpg?fm=webp&q=70');
        padding: 90px 130px;
        text-align: center;
        user-select: text;
        width: 60%;
        z-index: 2;

        @media only screen and (min-width: 1000px) and (max-width: 1280px) {
            padding: 30px 130px;
        }

        @media (max-width: $tp2-breakpoint-max) {
            padding: 88px 58px;
            width: 48%;
        }

        @media (max-width: $tp-breakpoint-max) {
            background-image: none;
            padding: 63px 130px 110px;
            width: auto;
        }

        @media (max-width: $sp-breakpoint-max) {
            padding: 5vw 8vw 6vw;
        }

        h3 {
            font-size: 2.9vw;
            font-style: italic;
            font-weight: normal;
            margin: 0 0 2.3vw;
            padding: 0;
            text-align: left;

            @media (max-width: $tp-breakpoint-max) {
                font-size: 36px;
                line-height: 53px;
                width: 100%;
            }

            @media (max-width: $sp-breakpoint-max) {
                font-size: 28px;
                line-height: 34px;
            }

            b,
            strong {
                font-style: normal;
                font-weight: bold;
            }
        }

        h2 {
            font-size: 2.9vw;
            font-style: italic;
            font-weight: normal;
            margin: 0 0 2.3vw;
            padding: 0;
            text-align: left;

            @media (max-width: $tp-breakpoint-max) {
                font-size: 36px;
                line-height: 53px;
                width: 100%;
            }

            @media (max-width: $sp-breakpoint-max) {
                font-size: 28px;
                line-height: 34px;
            }

            b,
            strong {
                font-style: normal;
                font-weight: bold;
            }
        }

        p {
            color: #000000;
            font-size: 1.1vw;
            letter-spacing: 0.017vw;
            line-height: 1.6;
            text-align: left;

            @media (max-width: $tp-breakpoint-max) {
                font-size: 16px;
                line-height: 24px;
                width: 100%;
            }
        }

        a {
            @include gold-background;

            border: 0;
            color: black;
            float: left;
            font-size: clamp(2vmin, 1rem, 5rem);
            padding: 10px 30px;

            @media (max-width: $tp-breakpoint-max) {
                bottom: auto;
                display: inline-block;
                float: none;
                left: 11%;
                padding: 10px 15%;
                position: relative;
                text-align: center;
            }

            @media (max-width: $sp-breakpoint-max) {
                padding: 10px 5%;
                position: static;
                width: 100%;
            }

            &.no-title {
                float: none;
                margin-left: 93px;
            }
        }
    }

    .position-center {
        @media (min-width: $tp-breakpoint-max) {
            padding: 22vw $left-dt;
        }

        @media (min-width: $xl-breakpoint-min) {
            padding: 22vw $left-dt;
        }

        @media (min-width: $tp2-breakpoint-max) and (max-width: 1366px) {
            padding: 22vw $left-dt;
        }
    }

    .paging {
        align-items: center;
        bottom: -4vw;
        display: inline-block;
        position: relative;
        right: 23vw;

        @media only screen and (min-width: 1981px) and (max-width: 7680px) {
            right: 26vw;
            bottom: -5vw;
            transform: scale(1);
        }

        @media only screen and (min-width: 4000px) and (max-width: 7680px) {
            transform: scale(2.8);
            right: 29vw;
            bottom: -4vw;
        }

        @media (max-width: $tp-breakpoint-max) {
            bottom: -10vw;
            left: auto;
        }

        @media (max-width: $sp-breakpoint-max) {
            bottom: -7vw;
            right: 6px;
        }

        .slick-prev,
        .slick-next {
            @include circled-arrow;

            color: black;
            float: left;
            transform: none;
        }

        .slick-prev {
            left: 25px;
            transform: rotate(180deg);

            @media (max-width: $tp2-breakpoint-max) {
                left: 60px;
                top: 16px;
            }

            @media (max-width: $sp-breakpoint-max) {
                left: 0;
            }
        }

        .slick-next {
            right: -41px;
            z-index: 3;

            @media (max-width: $tp2-breakpoint-max) {
                right: -53px;
                top: 16px;
            }

            @media (max-width: $sp-breakpoint-max) {
                right: -4px;
            }
        }

        .numbers {
            float: left;
            margin: 10px 25px;
            position: relative;
            right: -30px;

            @media (max-width: $tp2-breakpoint-max) {
                right: -55px;
                top: 17px;
            }

            @media (max-width: $sp-breakpoint-max) {
                bottom: -7vw;
                right: -2px;
            }
        }

        &.no-paragraph {
            bottom: 14vw;
        }
    }

    @media only screen and (max-width: 767px) {
        .slide-image {
            min-height: 328px !important;
        }
    }

    @media only screen and (min-width: 768px) {
        .slide-image {
            min-height: 512px !important;
        }
    }
}