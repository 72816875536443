@import './styles/global-variables';

.collection-banner {
    content-visibility: auto;
    height: 525px;
    overflow: hidden;
    padding: 21px 0;
    width: 100%;

    @media (max-width: $tp-breakpoint-max) {
        height: auto;
    }

    .collection-banner-element {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: center;
        text-decoration: none;
        width: 100%;

        .collection-banner-title {
            @media (max-width: $sp-breakpoint-max) {
                margin: 0 15%;
            }

            h2 {
                color: #000;
                font-size: 46px;
                line-height: 50.88px;
                margin: 0 0 30px;

                @media (max-width: $tp-breakpoint-max) {
                    font-size: 40px;
                    line-height: 48px;
                }

                @media (max-width: $sp-breakpoint-max) {
                    font-size: 28px;
                    line-height: 34px;
                }
            }
        }

        img {
            flex-shrink: 0;

            @media (max-width: $tp-breakpoint-max) {
                height: auto;
                width: 150%;
            }

            @media (max-width: $sp-breakpoint-max) {
                height: auto;
                width: 200%;
            }
        }
    }
}
