@import './styles/global-variables';

/*
    This SCSS is being used in multiple components (all components that are showing list of products in grid)
    - /ProductListing/Products
    - /SearchResults/ProductResults

    Don't change classNames or the structure without checking all components
*/

.products {
    margin-bottom: 90px;

    .products-count {
        color: $text-gray;
        font-size: 12px;
        margin: 15px 0;
        text-align: right;
    }

    .products-wrapper {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        &::after {
            background-color: white;
            bottom: 0;
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }

        .product-card-wrapper {
            border-bottom: 1px solid $medium-gray;
            border-right: 1px solid $medium-gray;

            &:last-child {
                border-right: 0;
            }

            @media(min-width: $dt-breakpoint-min) {
                flex-basis: 25%;

                @media screen and (min-width: 0\0) {
                    flex-basis: 24%;
                }

                &:nth-child(4n+1) {
                    border-left: 0;
                }

                &:nth-child(4n+0) {
                    border-right: 0;
                }
            }

            @media(min-width: $tp-breakpoint-min) and (max-width: $tp-breakpoint-max) {
                flex-basis: 33%;

                &:nth-child(3n+1) {
                    border-left: 0;
                }

                &:nth-child(3n+0) {
                    border-right: 0;
                }
            }

            @media(max-width: $sp-breakpoint-max) {
                flex-basis: 50%;

                &:nth-child(2n+1) {
                    border-left: 0;
                }

                &:nth-child(2n+0) {
                    border-right: 0;
                }
            }

            .product-card {
                min-height: 287px;
            }
        }

        .product-card {
            .product-benefit {
                color: #815b30;
                display: block;
                font-size: 14px;
                font-weight: normal;
                margin-bottom: 0;
                margin-top: 15px;
                padding: 0;
            }

            .product-name {
                display: block;
                font-size: 16px;
                font-weight: normal;
                margin: 6px 0 25px;
                padding: 0 22px;
            }

            .rating-numbers {
                color: $text-gray;
                display: block;
                font-size: 12px;
            }

            img {
                height: 163px;
                margin-bottom: 5px;
                width: 120px;
            }
        }
    }

    >button {
        @include golden-underline;
        align-self: center;

        background-color: white;
        border: 0;
        cursor: pointer;
        display: flex;
        font-size: 18px;
        margin: 50px auto;
        outline: 0;
        padding-bottom: 8px;
    }

    &.search-products {
        .product-card-wrapper {
            @media(min-width: $dt-breakpoint-min) {
                flex-basis: 20%;

                &:nth-child(4n+0) {
                    border-right: 1px solid $medium-gray;
                }

                &:nth-child(5n) {
                    border-right: 0;
                }
            }
        }
    }
}