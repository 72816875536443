@import './styles/global-variables';

.hair-quiz {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: auto;
    max-width: 1080px;
    padding: 21px 0;

    h3 {
        font-size: 46px;
        font-style: italic;
        font-weight: 400;
        margin: 0;
        text-align: center;

        &:nth-of-type(1) {
            strong,
            b {
                color: black;
            }
        }

        @media screen and (max-width: $tp-breakpoint-min) {
            font-size: 28px;
        }
    }

    .column-link {
        @include gold-background;

        font-weight: bold;
        margin-top: 33px;
        padding: 16px 32px;

        @media screen and (max-width: $content-width-dt) {
            margin-top: 5vw;
        }
    }

    img {
        height: auto;
        width: 100%;
    }
}
