
.loader-container {
    display: flex;
    height: 5em;
    justify-content: center;
    width: 100%;

    @media screen and (min-width: 0\0) {
        display: block;
    }
}

.loader {
    animation: load1 .8s infinite ease-in-out;
    animation-delay: -.16s;
    background: lightgray;
    color: lightgray;
    font-size: 8px;
    margin: 88px auto;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    width: 1em;

    &::before,
    &::after {
        animation: load1 .8s infinite ease-in-out;
        background: lightgray;
        content: '';
        position: absolute;
        top: 0;
        width: 1em;
    }

    &::before {
        animation-delay: -.32s;
        left: -1.5em;
    }

    &::after {
        left: 1.5em;
    }
}

@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
