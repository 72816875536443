@import './styles/global-variables';

.product-images {
    @media (max-width: $tp-breakpoint-max) {
        width: 80%;
    }

    .large-image {
        text-align: center;
        width: 185%;

        @media (max-width: $tp-breakpoint-max) {
            margin: 30px auto;
            width: 100%;
        }

        img {
            height: auto;
            max-width: 80%;

            @media (max-width: $sp-breakpoint-max) {
                max-width: 100%;
            }
        }
    }

    .images-navigation {
        margin-right: -47px;
        margin-top: 3vw;
        width: 31%;
    }

    .thumbnails {
        margin: auto;
        text-align: center;
        width: 84%;

        .slick-slide {
            img {
                display: block;
                height: auto;
                max-width: 100%;
            }
        }
    }

    .number-slider {
        margin: auto;
        text-align: center;

        span {
            bottom: 20px;
            display: inline-block;
            left: 125px;
            margin: 20px;
            position: relative;

            @media (max-width: $tp2-breakpoint-max) {
                bottom: -3px;
                left: 439px;
            }

            @media (max-width: $tp-breakpoint-max) {
                bottom: 15px;
                left: 268px;
            }

            @media (max-width: $sp-breakpoint-max) {
                left: 100px;
            }
        }
    }

    .slick-next,
    .slick-prev {
        display: block;
        position: absolute;
    }

    .slick-next {
        right: 35px;
        top: -53px;
        transform: rotate(-90deg);
    }

    .slick-prev {
        bottom: -56px;
        left: 30px;
        transform: rotate(90deg);
    }

    .mobile-arrow-prev {
        bottom: 22px;
        display: inline-block;
        left: -38px;
        transform: rotate(180deg);

        @media (max-width: $tp2-breakpoint-max) {
            bottom: 2px;
            left: 410px;
        }

        @media (max-width: $tp-breakpoint-max) {
            bottom: 24px;
            left: 238px;
        }

        @media (max-width: $sp-breakpoint-max) {
            bottom: 23px;
            left: 86px;
        }
    }

    .mobile-arrow-next {
        bottom: 67px;
        display: inline-block;
        left: 61px;

        @media (max-width: $tp2-breakpoint-max) {
            bottom: 2px;
            left: 473px;
        }

        @media (max-width: $tp-breakpoint-max) {
            bottom: 23px;
            left: 300px;
        }

        @media (max-width: $sp-breakpoint-max) {
            bottom: 66px;
            left: 187px;
        }
    }

    @media only screen and (max-width: 767px) {
        .large-image {
            min-height: 394px !important;
        }
    }

    @media only screen and (min-width: 768px) {
        .large-image {
            min-height: 513px !important;
        }
    }
}