@import './styles/global-variables';

.sort-container {
    align-items: center;
    color: $text-gray;
    display: flex;
    font-size: 16px;
    font-weight: normal;

    @media (max-width: $sp-breakpoint-max) {
        margin-top: 28px;
    }

    .sort-review-select {
        background-color: white;
        margin: 0 3px;
        padding: 10px;
        width: 164px;
    }
}
