@import './styles/global-variables';

.text-info-block {
    background-image: url('https://images.ctfassets.net/a8l3ylu84syn/30gWnstSMn8bQIQPyEiLxz/5120b80278f4a89ae26a2d5f633565a3/block-bg.jpg?fm=webp&q=70');
    background-position: center;
    background-repeat: repeat;
    box-shadow: $paper-box-shadow;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    max-width: 1080px;
    padding: 50px 110px;
    position: relative;
    text-align: left;

    @media screen and (min-width: 0\0) {
        background-image: url('https://images.ctfassets.net/a8l3ylu84syn/30gWnstSMn8bQIQPyEiLxz/5120b80278f4a89ae26a2d5f633565a3/block-bg.jpg?fm=webp&q=70');
    }

    @supports (-webkit-hyphens: none) {
        background-image: url('https://images.ctfassets.net/a8l3ylu84syn/30gWnstSMn8bQIQPyEiLxz/5120b80278f4a89ae26a2d5f633565a3/block-bg.jpg?fm=webp&q=70');
    }

    @media (max-width: 1180px) {
        width: calc(100% - 68px);
    }

    @media (max-width: $tp-breakpoint-max) {
        padding: 35px 45px 25px;

        .block-title-with-share {
            h1 {
                margin-bottom: 58px;

                @media (max-width: $sp-breakpoint-max) {
                    margin-bottom: 0;
                }
            }

            @media (max-width: $tp-breakpoint-max) {
                span {
                    p {
                        margin-top: 110px;
                    }
                }

                h1 {
                    font-size: 28px;
                }
            }
        }
    }

    @media (max-width: $sp-breakpoint-max) {
        padding: 31px 30px 17px;
        width: calc(100% - 30px);
    }

    &.pulled {
        margin: -120px auto auto;
        background: #F2F3F0;

        @media (max-width: $tp-breakpoint-max) {
            margin-top: -160px;
        }

        @media (max-width: $sp-breakpoint-max) {
            margin-top: -35px;
        }

        @media (min-width: $xl-breakpoint-min) {
            margin-top: -8.3333333vw;
        }
    }

    .block-title {
        width: 100%;

        h1 {
            margin-top: 0;

            a {
                color: #815b30;
            }
        }

        h2 {
            font-weight: normal;
            margin-top: 0;

            &:first-child {
                margin-bottom: 22px;
            }
        }

        a {
            color: black;
            display: inline-block;
            font-weight: bold;
            margin-top: 25px;
            padding: 3px 0;
        }

        p {

            &.emphasized,
            &.emphasised {
                font-weight: 700;
            }
        }
    }

    .share-button {
        position: relative;
        text-align: right;
        top: -31px;
        width: 100%;

        @media (max-width: $sp-breakpoint-max) {
            margin: 30px 0 30px 20px;
            text-align: left;
        }

        .button {
            position: relative;

            &::before {
                right: 52px;
                top: 6px;

                @media (max-width: $tp-breakpoint-max) {
                    left: -16px;
                }
            }
        }

        .before {
            z-index: 0;
        }

        .block {
            box-shadow: 0 1px 3px rgba(0, 0, 0, .5);
            position: relative;
        }

        .after {
            z-index: 2;
        }

        @media (max-width: $tp-breakpoint-max) {

            .tooltip {
                margin-left: -25px;
                right: auto;
                top: 30px;
                z-index: 1;

                .before {
                    box-shadow: -2px -2px 6px rgba(0, 0, 0, .5);
                    right: 80%;
                    top: -11%;
                }

                .block {
                    box-shadow: 0 -1px 3px rgba(0, 0, 0, .5);
                }

                .after {
                    right: 80%;
                    top: -10%;
                }
            }
        }
    }

    .wrapper-block-column {
        display: flex;

        @media (max-width: $sp-breakpoint-max) {
            flex-direction: column;
        }

        .block-column {
            position: relative;
            width: 50%;

            @media (max-width: $sp-breakpoint-max) {
                width: 100%;
            }

            h2,
            h3 {
                font-family: Didot, serif;
                font-size: 36px;
                font-weight: bold;
                margin-top: 0;

                i,
                em {
                    font-style: italic;
                    font-weight: normal;
                }
            }

            h2+h2,
            h3+h3 {
                margin-top: -30px;
            }

            .column-text {
                p {
                    color: $text-gray;
                    line-height: 1.5;
                    margin-top: 0;

                    a {
                        color: #815b30 !important;
                        text-decoration: underline;
                    }

                    strong {
                        color: black;

                        a {
                            color: #815b30 !important;
                        }
                    }
                }

                .gold {
                    margin: 0;
                }

                div {
                    ul {
                        li {
                            color: $text-gray;
                        }
                    }
                }
            }

            h4 {
                font-family: Didot, serif;
                font-size: 24px;
                margin-top: 14px;
                text-align: center;
            }

            .column-image {
                margin-bottom: 15px;
            }

            .column-image,
            .corner-image {
                img {
                    height: auto;
                    max-width: 100%;
                }
            }

            .column-link {
                @include gold-background;

                color: black;
                display: inline-block;
                font-weight: bold;
                margin-top: 25px;
                padding: 16px 32px;
            }

            p,
            span {

                &.emphasized,
                &.emphasised {
                    color: black;
                    font-size: 19px;
                    font-weight: bold;
                    line-height: 1.36;
                }

                &.headline {
                    font-size: 16px;
                    line-height: 1.5;
                }

                &.note {
                    color: $golden;
                    font-size: 15px;
                }
            }

            .empty-line {
                color: transparent;
                display: block;

                &::before {
                    content: '.';
                }
            }

            &.left-column {
                padding-right: 30px;

                @media (max-width: $tp-breakpoint-max) {
                    padding-right: 15px;
                }

                @media (max-width: $sp-breakpoint-max) {
                    padding-right: 0;
                }
            }

            &.right-column {
                padding-left: 30px;

                @media (max-width: $tp-breakpoint-max) {
                    padding-left: 15px;
                }

                @media (max-width: $sp-breakpoint-max) {
                    margin-top: 25px;
                    padding-left: 0;
                }

                blockquote {
                    margin-right: 33px;
                    @media (max-width: $tp-breakpoint-max) {
                        padding-top: 30px;
                        position: relative;
                    }

                    @media (max-width: $sp-breakpoint-max) {
                        margin-top: 0;
                        position: static;
                    }

                    p {
                        color: $golden;
                        font-family: Didot, serif;
                        font-size: 30px;
                        font-style: italic;
                        position: relative;

                        &::before,
                        &::after {
                            font-family: Didot, serif;
                            font-size: 100px;
                            font-style: normal;
                            line-height: 0;
                            position: absolute;
                        }

                        &::before {
                            content: '\201C';
                            left: -45px;
                            top: 10px;
                        }

                        &::after {
                            bottom: -30px;
                            content: '\201D';
                            right: -40px;
                        }

                        @media (max-width: $tp-breakpoint-max) {
                            font-size: 26px;
                        }
                    }
                }

                .corner-image {
                    position: relative;
                    right: -118px;
                    top: 20px;

                    @media (max-width: $tp-breakpoint-max) {
                        position: static;
                    }
                }

                .column-text {
                    p {
                        strong {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

.no-wrapper {
    @include block-wrapper-common;
}

.landingPageTextInfoBlock {
    background-color: none;
    display: flex;
    flex-direction: row;
    padding: 0px 0px !important;

    .block-column {
        padding: 80px 120px;
    }
}

.petaBlock-2 {
    box-shadow: none !important;
    margin-left: auto;
    background-image: url('https://images.ctfassets.net/xq8n4mwvbz58/47NWjdlI8Et6a1bestpQD2/32b257026d14063f9690e679d8799898/border-copy_1.png?h=250');
    background-repeat: no-repeat;
    margin-right: auto;
    background-size: 100% 350px;
    width: 60%;
    max-width: 1080px;

    .text-info-block {
        background: none;
        box-shadow: none;
        width: auto;
        margin-left: -30px;

    }

    .petaBlockImage {
        img {
            width: 200px;
            height: auto;
        }
    }
}

@media(max-width:830px) {
    .petaBlock-2 {
        width: 85vw;
    }

    .text-info-block {
        margin-left: 0px;
    }
}

@media(max-width:720px) {
    .petaBlock-2 {
        .wrapper-block-column {
            margin-left: -30px;
        }
    }
}


@media (max-width: 767px) {
    .petaBlock-2 {
        width: 85vw;

        .text-info-block {
            margin-left: 0px;
        }

        h2 {
            font-size: 28px;
            text-align: center;
        }

        .petaBlockImage {
            margin-left: 30px;
        }

        background-repeat: no-repeat;
        background-size: 100% 700px;
        box-shadow: none !important;
        padding: 30px 50px;
        background-image: url('https://images.ctfassets.net/xq8n4mwvbz58/26OiRkgQXhS0juYbT5FX67/d344227cd0ea6a70503fa608937ecda1/border-copy2_1.png');
    }
}

.component-6 {
    margin-top: 80px;
    margin-bottom: 0px;

    .kewLogo {
        display: none;
    }

    .tailimage {
        display: none;
    }

    .text-info-block {
        background: none;
        box-shadow: none;
    }

    .component-6-text-info-block .wrapper-block-column .left-column .column-text{
        text-align: center;
    }

    .component-6-text-info-block .wrapper-block-column .right-column .column-image{
        position: relative;
        height: 80%;
        margin-top: 50px;
    }

    .component-6-text-info-block .wrapper-block-column .right-column .column-image img{
        height: auto;
        width: -webkit-fill-available;
    }

    .component-6-text-info-block .wrapper-block-column .block-column h3{
        font-family: Didot,serif;
        font-size: 36px;
        font-weight: 700;
        margin-top: 0;
    }

    .component-6-text-info-block .wrapper-block-column .block-column p{
        color: #575757;
        line-height: 1.5;
        margin-top: 0;
    }

}

@media (max-width:921px) {
    .component-6 {
        background-image: url('https://images.ctfassets.net/xq8n4mwvbz58/5ZFOSzhVD2x6c0DUbI6T5G/1e9f2fb630ea0072b0812761bca9d864/Component_6_image.jpg?fm=webp');
        background-size: 100%;
        background-position: 0px 0px;
        max-width: 820px;
        margin-left: 0;
        margin-bottom: 50px;

        .kewLogo {
            display: none;
        }

        .tailimage {
            display: block;

            img {
                position: absolute;
                margin-top: -125px;
                margin-left: 310px;
                z-index: -1;
            }
        }

        .component-6-text-info-block .wrapper-block-column .right-column .column-image {
            display: none;
        }

        .text-info-block .wrapper-block-column {
            flex-direction: row;
        }

        .component-6-text-info-block .wrapper-block-column .left-column .column-text {
            background-image: url('https://images.ctfassets.net/xq8n4mwvbz58/1p1HptPhZYv0LBiRmZAXbO/2384d8cf2cc2bbeb33e8bf7446b32055/Rectangle_118.jpg');
            box-shadow: $paper-box-shadow;
            width: 600px;
            margin-left: 90px;
            margin-right: auto;
            padding: 30px 50px;
            text-align: center;
            margin-top: 184px;
        }

        .component-6-text-info-block .wrapper-block-column .left-column{
            padding-right: 15px;
            position: relative;
            width: 50%;
        }

        .component-6-text-info-block .wrapper-block-column{
            display: flex;
            flex-direction: row;
        }

    }
}

@media (max-width:700px) {

    .component-6 {
        background-image: url('https://images.ctfassets.net/xq8n4mwvbz58/69eRj4u1uEGPHrPb1hXlXD/8dc850d87c2a5a029a83d2a96afb44e3/BS_Aloe_AVocado_Modul_6_2.png?h=250');
        height: 600px;
        margin-bottom: 50px;

        .kewLogo {
            display: block;

            img {
                position: absolute;
                z-index: 1;
                width: 50%;
                margin-left: 100px;
                margin-top: 50px;
            }
        }

        .tailimage {
            display: block;
            margin-top: 47px;

            img {
                position: absolute;
                margin-top: 320px;
                margin-left: 90px;
                z-index: -1;
            }
        }

        .component-6-text-info-block .wrapper-block-column .left-column .column-text {
            background-image: url('https://images.ctfassets.net/xq8n4mwvbz58/1p1HptPhZYv0LBiRmZAXbO/2384d8cf2cc2bbeb33e8bf7446b32055/Rectangle_118.jpg');
            box-shadow: $paper-box-shadow;
            width: 80vw;
            position: absolute;
            margin-top: 165px;
            padding: 10px 10px;
            padding-top: 75px;
        }

        .component-6-text-info-block .wrapper-block-column .right-column .column-image{
            display: none;
        }

        .component-6-text-info-block .wrapper-block-column .left-column .column-text {
            margin-left: 36px;
        }

    }
}


.component-2 {
    height: 45vw;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("https://images.ctfassets.net/xq8n4mwvbz58/5TBOGuXa3G98qlq6cSVSqf/5644e8c8b390ed897ee1545898a7f110/BS_Aloe_AVocado_Modul_3_2.jpg");

    .component-2-text-info-block {
        background: none;
        box-shadow: none;
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        max-width: 1080px;
        padding: 50px 110px;
        position: relative;
        text-align: left;

        .wrapper-block-column {
           
            background-size: 100% 100%;
            background-repeat: no-repeat;

            height: 420px;
            display: flex;

            .left-column {
                position: relative;
                width: 50%;
                padding-right: 30px;
                .column-text {
                    width: 100%;
                    padding-top: 12%;
                    margin-left: -40%;

                    h2 {
                        color: #FFFFFF !important;
                        font-size: 50px;
                        font-family: 'Didot';
                        font-weight: 400;
                        line-height: 72px;

                    }

                    p {
                        color: #FFFFFF !important;
                        font-size: 22px !important;
                        line-height: 35px;
                        font-weight: 400;
                        font-family: 'Avenir Next LT Pro';
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1230px) and (min-width: 1100px){
        .component-2-text-info-block {
            .wrapper-block-column {
                .left-column {
                    .column-text {
                        padding-top: 5%;
                        margin-left: -25%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1100px) and (min-width: 991px){
        .component-2-text-info-block {
            .wrapper-block-column {
                .left-column {
                    .column-text {
                        padding-top: 5%;
                        margin-left: -25%;
                        h2{
                            font-size: 45px!important;
                        }
                        p{
                            font-size: 20px!important;
                        }
                    }
                }
            }
        }
    }

    @media(max-width:991px) {
        height: 55vw;
        .component-2-text-info-block {
            .wrapper-block-column {
                .left-column {
                    .column-text {
                        padding-top: 0%;
                        margin-left: -25%;
                        margin-top: -3%;

                        h2 {
                            font-size: 35px!important;
                            line-height: 50px!important;
                        }

                        p {
                            font-size: 15px !important;
                            line-height: 32px!important;
                        }
                    }
                }
            }
        }
    }


    @media(max-width:760px) {
        background-image: none;
        height: auto;

        .component-2-text-info-block {
            display: block;
            background: local;
            box-shadow: $paper-box-shadow;
            margin-left: auto;
            margin-right: auto;
            box-shadow: none;

            width: 83%;
            padding: 0px;

            .wrapper-block-column {
                background: none;
                height: auto;
                display: block;

                .left-column {
                    background-image: url('https://images.ctfassets.net/xq8n4mwvbz58/1p1HptPhZYv0LBiRmZAXbO/2384d8cf2cc2bbeb33e8bf7446b32055/Rectangle_118.jpg');
                    padding: 10px 20px;
                    width: 100%;

                    .column-text {
                        padding: 0px;
                        width: 80%;
                        margin-left: 0%;

                        h2 {
                            color: black !important;
                            font-size: 28px;
                            font-family: 'Didot';
                            line-height: normal;

                        }

                        p {
                            color: black !important;
                            font-size: 12px !important;
                            line-height: 18px !important;
                        }
                    }
                }

                .right-column {
                    display: none;
                }
            }
        }
        .block-image{
            background-repeat: no-repeat;
            background-image: url("https://images.ctfassets.net/xq8n4mwvbz58/4BmLMN9vdlqLeqy9acKHSz/71245a4ca7690698c76225e8122a3776/Component_2_image.jpg");
            height: 50vw;
            background-size: 100%;
        }

    }
}

.component-5 {
    margin-bottom: -150px;

    .text-info-block {
        background: none;
        box-shadow: none;
    }

    .component-5-text-info-block{
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        position: relative;
        text-align: left;
        margin-top: 150px;
        margin-bottom: 50px;

        .wrapper-block-column{
            display: flex;
        }
    
        .wrapper-block-column .left-column,
        .wrapper-block-column .right-column{
            position: relative;
            width: 50%;
        }

        .wrapper-block-column .left-column .column-image img,
        .wrapper-block-column .right-column .column-image img{
            height: auto;
            max-width: 100%;
        }

        .wrapper-block-column .left-column .column-text,
        .wrapper-block-column .right-column .column-text{
            background-image: url('https://images.ctfassets.net/xq8n4mwvbz58/1p1HptPhZYv0LBiRmZAXbO/2384d8cf2cc2bbeb33e8bf7446b32055/Rectangle_118.jpg');
        }

        .wrapper-block-column .left-column .column-text{
            margin-top: -119px;
            text-align: center;
            z-index: 3;
            position: relative;
            width: 50%;
            margin-left: 120px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 10px;
            padding-right: 10px
        }

        .wrapper-block-column .right-column .column-text{
            position: relative;
            width: 50%;
            text-align: center;
            margin-left: 120px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 10px;
            padding-right: 10px;
            margin-top: -69px;
        }

        .wrapper-block-column .left-column .column-text p,
        .wrapper-block-column .right-column .column-text p{
            color: #575757;
            line-height: 1.5;
            margin-top: 0;
        }

        .wrapper-block-column .right-column .column-image{
            margin-top: -99px;
        }

    }

    @media (min-width:900px) {

        .text-info-block .wrapper-block-column {
            .left-column {
                .column-text {
                    width: 100%;
                    margin-left: -75px;
                    margin-top: -90px !important;
                    position: sticky;
                }
            }

            .right-column {
                margin-top: 100px;

                .column-text {
                    position: sticky;
                }

                .column-image {
                    width: 400px;
                    height: 400px;
                    margin-top: -100px;
                    margin-left: 75px;
                }
            }

            .block-column {
                .column-text {
                    background-image: url('https://images.ctfassets.net/xq8n4mwvbz58/1p1HptPhZYv0LBiRmZAXbO/2384d8cf2cc2bbeb33e8bf7446b32055/Rectangle_118.jpg');
                    padding: 40px 0px 20px 70px;
                    box-shadow: $paper-box-shadow;
                    margin-top: 0px;
                }

                .column-image {
                    margin-bottom: 0px;
                }
            }

        }
    }

    @media (min-width:700px) {
        margin-bottom: -150px;

        .text-info-block .wrapper-block-column {
            margin-left: 10px;

            .block-column {
                margin-bottom: 10px;

                .column-image {
                    img {
                        width: 100%;
                    }
                }

                .column-text {
                    text-align: left;
                }
            }
        }

        .text-info-block .wrapper-block-column .right-column {
            margin-left: -25px !important;
        }
    }

    @media (max-width:899px) {
        margin-bottom: -100px;
        margin-top: 200px;

        .component-5-text-info-block{
            display: flex;
            flex-wrap: wrap;
            position: relative;
            text-align: left;
            margin: auto;
        }

        .component-5-text-info-block .wrapper-block-column{
            display: flex;
            flex-direction: column!important;
            flex-wrap: wrap;
        }

        .component-5-text-info-block .wrapper-block-column .left-column{
            width: 90vw;
            margin-left: 20px;
            display: flex;
            flex-direction: column-reverse;
            margin-bottom: 50px;
        }

        .component-5-text-info-block .wrapper-block-column .left-column .column-text{
            text-align: left;
            width: 100%;
            margin-left: 0px;
        }

        .component-5-text-info-block .wrapper-block-column .left-column .column-text p,
        .component-5-text-info-block .wrapper-block-column .right-column .column-text p{
            color: #575757;
            line-height: 1.5;
            margin-top: 0;
        }

        .component-5-text-info-block .wrapper-block-column .right-column{
            width: 90vw;
            margin-left: 20px;
            margin-top: 100px;
            margin-bottom: 25px;
        }

        .component-5-text-info-block .wrapper-block-column .right-column .column-text{
            margin-top: 0px;
            width: 100%;
            margin-left: 0px;
            text-align: left;
        }

        .component-5-text-info-block .wrapper-block-column .right-column .column-image{
            margin-top: 0px;
        }
    }

    @media(max-width: 1050px){
        .component-5-text-info-block .wrapper-block-column .right-column .column-text{
            margin-top: -92px;
        }
    }
}
