@import './styles/global-variables';

.hamburger {
    transition: all .3s ease-in-out;

    .line {
        background-color: #80592e;
        display: block;
        height: 3px;
        margin: 6px auto;
        transition: all .3s ease-in-out;
        width: 32px;

        @media (max-width: $sp-breakpoint-max) {
            width: 27px;
        }
    }

    &.is-active {
        animation: smallbig .6s forwards;

        .line {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                transition-delay: .2s;
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(1) {
                transform: translateY(10px) rotate(45deg);
            }

            &:nth-child(3) {
                transform: translateY(-8px) rotate(-45deg);
            }
        }
    }

    &:hover {
        cursor: pointer;
    }
}

@keyframes smallbig {
    0%,
    100% {
        transform: scale(.7);
    }

    50% {
        transform: scale(0);
    }
}
