@import './styles/global-variables';

.table-info-block {
    background-image: url('https://images.ctfassets.net/a8l3ylu84syn/30gWnstSMn8bQIQPyEiLxz/5120b80278f4a89ae26a2d5f633565a3/block-bg.jpg?fm=webp&q=70');
    background-position: center;
    background-repeat: repeat;
    box-shadow: $paper-box-shadow;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    max-width: 1080px;
    padding: 50px 120px;
    position: relative;
    text-align: left;

    @media screen and (min-width: 0\0) {
        background-image: url('https://images.ctfassets.net/a8l3ylu84syn/30gWnstSMn8bQIQPyEiLxz/5120b80278f4a89ae26a2d5f633565a3/block-bg.jpg?fm=webp&q=70');
    }

    @media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
        background-image: url('https://images.ctfassets.net/a8l3ylu84syn/30gWnstSMn8bQIQPyEiLxz/5120b80278f4a89ae26a2d5f633565a3/block-bg.jpg?fm=webp&q=70');
    }

    @media (max-width: 1180px) {
        width: calc(100% - 68px);
    }

    @media (max-width: $tp-breakpoint-max) {
        padding: 16px 45px 25px;
    }

    @media (max-width: $sp-breakpoint-max) {
        padding: 13px 30px 17px;
        width: calc(100% - 30px);
    }

    &.pulled {
        margin: -120px auto auto;

        @media (max-width: $tp-breakpoint-max) {
            margin-top: -160px;
        }

        @media (max-width: $sp-breakpoint-max) {
            margin-top: -85px;
        }

        @media (min-width: $xl-breakpoint-min) {
            margin-top: -8.3333333vw;
        }
  }

    .resp-table {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 3em;
        padding: 0;
    }

    .resp-td {
        box-sizing: border-box;
        flex-grow: 1;
        overflow: hidden;
        width: 50%;

        &.title-cell {
            order: 1;
            @media (max-width: $sp-breakpoint-max) {
                margin-top: 30px;
            }
        }

        &.image-cell {
            margin-bottom: 15px;
            order: 2;

            img {
                max-width: 100%;

                @media (max-width: $tp-breakpoint-max) {
                    height: 185px;
                    width: 285px;
                }
            }
        }

        &.text-cell {
            order: 3;

            p {
                color: $text-gray;
                line-height: 1.5;
                margin-top: 0;

                &.emphasized,
                &.emphasised {
                    color: black;
                    font-size: 19px;
                    font-weight: bold;
                    line-height: 1.36;
                }

                &.note {
                    color: $golden;
                    font-size: 15px;
                }
            }

            span {
                &.emphasised {
                    color: black;
                    font-size: 19px;
                    line-height: 1.36;
                }

                &.note {
                    color: $golden;
                    font-size: 15px;
                }
            }
        }

        &.left {
            padding-right: 30px;

            @media (max-width: $tp-breakpoint-max) {
                padding-right: 15px;
            }
            @media (max-width: $sp-breakpoint-max) {
                padding-right: 0;
            }
        }

        &.right {
            padding-left: 30px;
            @media (max-width: $tp-breakpoint-max) {
                padding-left: 15px;
            }
            @media (max-width: $sp-breakpoint-max) {
                padding-left: 0;
            }
        }

        > h1,
        > h2 {
            margin-top: 0;
        }
  }

    @media all and (max-width: $sp-breakpoint-max) {
        .resp-table  {
            display: block;
            > .resp-td { width: 100% !important; }
        }
    }



}

.no-wrapper {
    @include block-wrapper-common;
}
