@import './styles/global-variables';

.product-listing {
    background-position: calc(50% - 760px) center, calc(50% + 760px) center;
    background-repeat: no-repeat;
    position: relative;

    &.over {
        overflow: hidden;
    }

    .left-bg-image,
    .right-bg-image {
        position: absolute;
        top: 250px;
        z-index: -10;

        @media (max-width: $tp-breakpoint-max) {
            display: none;
        }
    }

    .left-bg-image {
        left: 0;
    }

    .right-bg-image {
        right: 0;
    }

    .content {
        display: flex;
        justify-content: center;
        margin: auto;
        width: $content-width-dt;

        @media (max-width: $tp-breakpoint-max) {
            width: $content-width-tb;
        }

        @media (max-width: $sp-breakpoint-max) {
            width: 100%;
        }

        .sidebar {
            width: 30%;

            @media (max-width: $dt-breakpoint-min) {
                display: none;
            }
        }

        .main {
            display: flex;
            flex-direction: column;
            width: 100%;

            @media (max-width: $sp-breakpoint-max) {
                width: 90%;
            }

            .collections-container {
                border-bottom: 1px solid gray;
                margin-bottom: 10px;
                padding: 15px;
                text-align: center;

                @media (min-width: $dt-breakpoint-min) {
                    display: none;
                }
            }

            .collections-button {
                @include primary-button;

                padding: 15px;
                width: 150px;

                @media (max-width: $sp-breakpoint-max) {
                    margin: 10px auto 0;
                    width: 100%;
                }
            }

            .filter-by-button {
                @include secondary-button;

                padding: 15px;
                width: 150px;

                @media (max-width: $sp-breakpoint-max) {
                    border: 1px solid black;
                    margin: 0 auto;
                    width: 100%;
                }
            }

            .products-header {
                align-items: center;
                border-bottom: 3px solid $dark-gray;
                color: $text-gray;
                display: flex;
                font-size: 14px;
                font-weight: normal;
                height: 64px;
                padding: 10px;

                span {
                    display: inline-block;
                    min-width: 80px;
                }
            }
        }

        .filter-by {
            align-items: center;
            border-bottom: 2px solid gray;
            color: $text-gray;
            display: flex;
            font-size: 14px;
            font-weight: normal;
            padding: 5px 5px 20px;
        }

        .selected-label {
            color: $text-gray;
            display: inline-block;
            font-size: 14px;
            margin: 10px;
            width: 80px;

            @media (max-width: $tp-breakpoint-max) {
                display: none;
            }
        }

        .filters-reset {
            @include primary-button;

            display: inline;
            margin-right: 8px;

            @media (max-width: $sp-breakpoint-max) {
                @include secondary-button;

                display: block;
                margin: 20px auto;
                order: 4;
                width: 165px;
            }

            &.disabled {
                display: none;

                @media (max-width: $sp-breakpoint-max) {
                    @include secondary-button;

                    display: block;
                }
            }

            .remove-icon {
                height: 10px;
                width: 10px;

                &::before {
                    content: '\2715';
                    cursor: pointer;
                    padding: 5px;
                }

                @media (max-width: $sp-breakpoint-max) {
                    display: none;
                }
            }
        }

        .apply-button {
            @include primary-button;

            display: block;
            margin: auto;
            width: 165px;

            @media (min-width: $dt-breakpoint-min) {
                display: none;
            }

            @media (max-width: $sp-breakpoint-max) {
                order: 5;
            }

            &.disabled {
                background-color: #7f7f7f;
            }
        }
    }

    .modal {
        font-size: 16px;
        width: 90%;
    }
}
