@import './styles/global-variables';

.contact-us {
    background-position: left center, right center;
    background-repeat: no-repeat, no-repeat;
    margin-bottom: 70px;
    width: 100%;

    @media (max-width: $tp-breakpoint-max) {
        margin-top: 110px;
    }

    @media (max-width: 1120px) {
        background-image: none !important;
    }

    iframe {
        border: 1px solid #707070;
        display: block;
        margin: 50px auto 0;
        min-height: 500px;
        width: 800px;

        @media (max-width: $tp-breakpoint-max) {
            min-height: 600px;
            width: 85%;
        }
    }

    .contact-us-heading {
        width: 800px;
        margin: 50px auto;

        h1 {
            font-style: normal;
        }

        @media (max-width: $tp-breakpoint-max) {
            width: 650px;
        }

        @media (max-width: $sp-breakpoint-max) {
            width: 330px;
        }
    }
}
