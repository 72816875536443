@import './styles/global-variables';

table {
    border-collapse: collapse;
    margin: auto;
    margin-bottom: 50px;
    width: 70%;
}

th {
    background: rgb(250, 249, 248);
    color: black;
    font-size: 36px;
    font-weight: bold;
}

td,
th {
    border: 1px solid #ede9e3;
    padding: 18px 6px;
    text-align: left;
}

tr {
    &:nth-of-type(odd) {
        background: #eee;
    }
}

@media only screen and (max-device-width: $tp2-breakpoint-max) {
    table {
        margin: auto;
        margin-bottom: 50px;
        width: 70%;
    }

    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
	}

    thead {
        tr {
            left: -9999px;
            position: absolute;
            top: -9999px;
        }
	}

    tr {
        border: 1px solid #ccc;
    }

    td {
        border: 0;
        border-bottom: 1px solid #eee;
        padding-left: 50%;
        position: relative;

        &::before {
            left: 6px;
            padding-right: 10px;
            position: absolute;
            white-space: nowrap;
            width: 45%;
        }

        &:nth-of-type(1) {
            &::before {
                content: 'Ingredient Name';
            }
        }

        &:nth-of-type(2) {
            &::before {
                content: 'Purpose';
            }
        }

        &:nth-of-type(3) {
            &::before {
                content: 'Description';
            }
        }
	}
}

@media only screen and (max-width: 760px) {
    table {
        width: 100%;
    }
}
