@import './styles/global-variables';

.share-button {
    cursor: pointer;
    margin-left: 20px;
    position: relative;

    .tooltip {
        position: absolute;
        right: 0;
        top: -50px;

        img {
            margin: 0 10px;
        }

        button {
            margin: 0 5px;
        }

        &.hidden {
            display: none;
        }

        .block {
            background-color: white;
            border: 1px solid lightgray;
            box-shadow: 0 1px 3px rgba(0, 0, 0, .5);
            display: flex;
            margin: auto;
            padding: 8px;
            z-index: 1;

            button {
                &:focus {
                    @include focusable-item;
                }
            }
        }

        .before {
            border: 1px solid lightgray;
            box-shadow: 3px 3px 6px rgba(0, 0, 0, .5);
            content: '';
            height: 13px;
            margin-left: -50px;
            position: absolute;
            right: 20%;
            top: 75%;
            transform: rotate(45deg);
            width: 13px;
            z-index: -2;
        }

        .after {
            background-color: white;
            content: '';
            height: 13px;
            margin-left: -50px;
            position: absolute;
            right: 20%;
            top: 75%;
            transform: rotate(45deg);
            width: 13px;
        }
    }

    .button {
        border-radius: 5px;
        padding: 5px;

        &::before {
            background-image: url('https://images.ctfassets.net/mri7fdzlxw7c/69WIJlaZQXZwbsm6spEMqm/dcc946a47cca4bb84359dbcce86114e3/share_ico.png');
            background-repeat: no-repeat;
            content: '';
            height: 20px;
            left: -20px;
            position: absolute;
            top: 3px;
            width: 20px;
        }
    }
}
