@import './styles/global-variables';

.navigation {
    background-color: white;
    box-shadow: 0 2px 3px #dadada;
    display: flex;
    justify-content: flex-end;
    padding-right: 3vw;

    @media (max-width: $sp-breakpoint-max) {
        background-color: #fff;
        height: 97px;
        position: relative;
        z-index: 6;
    }

    @media (max-width: $sp-breakpoint-max) {
        height: 66px;
    }

    .mobile-menu-icon {
        display: none;

        @media (max-width: 1400px) {
            bottom: 32px;
            display: inline;
            left: 54px;
            position: absolute;
        }

        @media (max-width: $sp-breakpoint-max) {
            bottom: 16px;
            left: 22px;
        }
    }

    > ul {
        margin: 0;
        padding: 0;

        @media (max-width: 1400px) {
            display: none;
        }

        > li {
            cursor: pointer;
            display: inline-block;
            margin: 0;
            padding: 35px 2vw;

            > a,
            span {
                color: #353535;
                cursor: pointer;
                font-size: 14px;
                font-weight: bold;
                padding-bottom: 5px;
                position: relative;
                text-decoration: none;
                text-transform: uppercase;

                &::after {
                    border: solid black;
                    border-width: 0 1px 1px 0;
                    content: '';
                    display: inline-block;
                    margin-left: 12px;
                    padding: 3px;
                    position: absolute;
                    top: 1px;
                    transform: rotate(45deg);
                }

                &:focus {
                    @include focusable-item;
                }

                &.link {

                    &::after {
                        display: none;
                    }
                }
            }

            a {
                &.active {
                    @include golden-underline;
                }
            }
        }

        .link {
            &.current {
                @include golden-underline;
            }
        }
    }

    .wall-menu-item {
        background-color: white;
        background-position: left -150px bottom, right -150px center;
        background-repeat: no-repeat, no-repeat;
        background-size: 399px, 400px;
        border-top: 1px solid lightgray;
        box-shadow: 0 6px 10px -5px rgba(0, 0, 0, .15);
        cursor: default;
        display: none;
        height: 460px;
        left: 0;
        min-height: 460px;
        opacity: 0;
        overflow-y: auto;
        position: absolute;
        top: 130px;
        width: 100%;
        z-index: 9;

        .all-products-link {
            @include gold-background;

            bottom: 0;
            color: #000;
            font-size: 14px;
            font-weight: 700;
            left: 50%;
            margin: 1.75vw auto;
            padding: 16px 20px;
            position: absolute;
            text-transform: uppercase;
        }

        .items {
            color: #383838;
            flex-basis: 1;
            flex-grow: 1;
            margin: 0 5px;
            min-height: 478px;
            padding: 2.6vw 1.5vw;
            position: relative;

            .column-image {
                height: 359px;
                width: 240px;
            }

            &::after {
                border-left: 1px solid $light-orange;
                content: '';
                height: 90%;
                margin-bottom: auto;
                margin-top: auto;
                position: absolute;
                right: 0;
                top: 0;
            }

            &:last-child {
                border-right: 0;
                margin-right: 0;
            }

            &:first-child {
                display: flex;
                flex-grow: 0;
                justify-content: center;
                padding: 3.5vw;
            }

            ul {
                padding: 0;

                .link {
                    color: $text-gray;
                    display: inline-block;
                    font-size: 16px;
                    padding: 8px 0;
                    text-decoration: none;

                    &:focus {
                        @include focusable-item;
                    }
                }

                li {
                    display: block;
                    margin: 0;
                    padding: 0;

                    .internal-link {
                        color: $golden;
                        display: inline-block;
                        font-size: 12px;
                        padding: 10px 0;
                        text-transform: uppercase;
                    }

                    .arrow {
                        @include no-circle-right-arrow-icon;

                        bottom: -3px;
                        position: relative;
                    }

                    &:last-child {
                        li {
                            margin-bottom: 40px;
                        }
                    }
                }
            }

            .column-heading {
                color: #000;
                font-family: Avenir, sans-serif;
                font-size: 14px;
                font-weight: 700;
                line-height: 19px;
                text-transform: uppercase;

                a {
                    color: #000;
                }
            }
        }
    }

    ul {

        li {

            &:hover {

                .wall-menu-item {
                    animation: fadeIn 300ms forwards;
                    display: flex;
                }
            }

            .wall-menu-item {
                &.active {
                    animation: fadeIn 300ms forwards;
                    display: flex;
                    padding-bottom: 3.5vw;
                }
            }
        }
    }
}

.skip-main {
    height: 1px;
    left: -999px;
    overflow: hidden;
    position: absolute;
    top: auto;
    width: 1px;
    z-index: -999;

    &:focus,
    &:active {
        background-color: #000;
        color: #fff;
        font-size: 1.2em;
        height: auto;
        left: auto;
        overflow: auto;
        padding: 5px;
        text-align: center;
        top: auto;
        width: 30%;
        z-index: 999;
    }
}

.hiddenText {
    height: 1px;
    left: -999px;
    overflow: hidden;
    position: absolute;
    top: auto;
    width: 1px;
    z-index: -999;

    &:focus,
    &:active {
        background-color: #000;
        color: #fff;
        font-size: 1.2em;
        height: auto;
        left: auto;
        overflow: auto;
        padding: 5px;
        text-align: center;
        top: auto;
        width: 30%;
        z-index: 999;
    }

    @media (max-width: $sp-breakpoint-max) {
        display: none;
    }
}
