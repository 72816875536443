@import './styles/global-variables';

.product-collections {
    margin-right: 10px;

    .select-collection {
        border-bottom: 3px solid $dark-gray;
        color: $text-gray;
        font-family: Avenir, sans-serif;
        font-size: 14px;
        font-weight: normal;
        margin: 0;
        padding: 22px 0;
    }

    ul {
        margin: 0;
        padding: 0 0 10px;
    }

    li {
        border-bottom: 1px solid $medium-gray;
        list-style: none;
        margin: 0;
        min-height: 60px;

        a {
            align-items: center;
            color: $text-gray;
            display: flex;
            padding: 10px 0;

            img {
                height: 40px;
                margin: 0 10px;
                width: 40px;
            }

            p {
                margin: 0;
            }

            &:hover {
                color: $dark-green;
            }
        }

        &.selected {
            a {
                color: $dark-green;
                font-weight: bold;
            }
        }
    }
}