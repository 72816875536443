@import './styles/global-variables';

$col-padding: 10px;

.link-block {
    overflow: hidden;
    padding: 70px 15px;
    position: relative;
    width: 100%;

    @media (max-width: $sp-breakpoint-max) {
        padding-top: 0;
    }

    .link-block-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        max-width: calc(#{$content-width-dt} + (#{$col-padding} * 2));
        text-align: center;

        a {
            color: #fff;
            display: inline-block;
            font-size: 28px;
            height: 104px;
            padding: 0 $col-padding;
            position: relative;
            width: 33%;

            @media (max-width: $tp-breakpoint-max) {
                width: 42%;
            }

            @media (min-width: 768px) {
                margin-bottom: 10px;
            }

            .block-content {
                align-items: center;
                background-position: center;
                background-size: cover;
                display: flex;
                font-family: Didot, serif;
                height: 100%;
                justify-content: space-between;
                line-height: 1.3;
                padding: 25px;
                text-align: left;
                width: 100%;
                background:#000;

                h3 {
                    font-size: 26px;
                    margin-right: 21px;
                    width: 80%;

                    @media (max-width: $tp-breakpoint-max) {
                        font-size: 24px;
                        width: 80%;
                    }

                    @media (max-width: $tp-breakpoint-max) {
                        font-size: 22px;
                    }
                }

                .arrow-right {
                    @include circled-arrow;

                    background-color: rgba(255, 255, 255, .2);
                    border: 1px #fff solid;

                    &::before {
                        background-color: white;
                    }

                    &::after {
                        border-right: 2px solid white;
                        border-top: 2px solid white;
                    }
                }

                @media (max-width: $sp-breakpoint-max) {
                    padding: 32px 6vw;
                }
            }

            @media (max-width: $sp-breakpoint-max) {
                display: block;
                font-size: 22px;
                height: 124px;
                margin: 0 auto;
                padding: 10px 0;
                width: 100%;
            }
        }
    }

    img {
        position: absolute;
        right: calc(50% - 610px);
        top: 15px;
        width: 260px;
        z-index: -1;

        @media (max-width: $tp-breakpoint-max) {
            display: none;
        }
    }
}
