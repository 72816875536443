@import './styles/global-variables';

.ps-widget {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin-bottom: 20px; /* Add space below the button */

    .bn-enabled {
        background-color: black !important;
        border-radius: 0 !important;
        height: 42px !important;
        padding: 0 34px !important;
        font-family: 'Arial';
        font-size: 16px;
        line-height: 40px;
        font-weight: 400;
        color: #fff;
        display: block;
        cursor: pointer; /* Change cursor to hand on hover */

        @media (max-width: $sp-breakpoint-max) {
            margin-top: 30px !important;
            width: 100%;

            .ps-widget {
                [ps-sku] {
                    width: 100%;
                }
            }
        }
    }

    .bn-enabled:hover {
        cursor: pointer; /* Ensure cursor changes on hover */
    }
}
