@import './styles/global-variables';

.circled-arrow {
    @include circled-arrow;

    &.slick-prev,
    &.slick-next {
        @include circled-arrow;
    }

    &.slick-prev {
        transform: rotate(180deg);
    }
}
