@import './styles/global-variables';

.text-table-container {
    background-position: calc(50% - 740px) center, calc(50% + 740px) center;
    background-repeat: no-repeat, no-repeat;
    background-size: 399px, 400px;
    padding-bottom: 40px;
    text-align: center;
    width: 100%;

    @media(min-device-width: $tp2-breakpoint-max) and (max-device-width: 1100px) {
        background-size: 0, 0;
    }

    @media(max-width: $tp-breakpoint-max) {
        background-size: 0, 0;
    }

    .text-table-container-title {
        h2 {
            justify-self: center;
            margin: 0 auto;
            max-width: 741px;

            @media(max-width: $tp-breakpoint-max) {
                max-width: 610px;
            }

            @media(max-width: $sp-breakpoint-max) {
                max-width: 221px;
            }
        }
    }

    .text-table {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: 1180px;

        .text-block {
            padding: 20px 50px;
            width: 25%;

            @media(max-width: $tp-breakpoint-max) {
                padding: 25px;
            }

            @media(max-width: $sp-breakpoint-max) {
                padding: 15px;
                width: 50%;
            }

            h4 {
                color: #000;
                font-family: Avenir, sans-serif;
                font-size: 19px;
                font-weight: 400;
                line-height: 26px;

                @media(max-width: $tp-breakpoint-max) {
                    font-size: 16px;
                    line-height: 24px;
                }

            }

            p {
                color: $text-gray;
                line-height: 1.5;
                margin-top: 1px;

            }
        }
    }
}

.lpHeading-1 {
    width: 60%;
    margin-left: auto;
    margin-right: auto;

    .text-table-container-title h2 {
        max-width: none;
        font-size: 38px;
    }

    .text-table-container-title p {
        font-size: 14px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media(max-width:700px) {
    .lpHeading-1 {
        width: 90%;

        .text-table-container-title h2 {
            font-size: 24px;
        }

        .text-table-container-title p {
            font-size: 14px;
            text-align: justify;
        }
    }
}

.landingpage-text-table-container {
    background-position: calc(50% - 740px) center, calc(50% + 740px) center;
    background-repeat: no-repeat, no-repeat;
    background-size: 399px, 400px;
    padding-bottom: 40px;
    text-align: center;
    width: 100%;
    display: none;

    @media(min-device-width: $tp2-breakpoint-max) and (max-device-width: 1100px) {
        background-size: 0, 0;
        display: none;
    }

    @media(max-width: $tp-breakpoint-max) {
        background-size: 0, 0;
        display: none;
    }

    @media(max-width: $sp-breakpoint-max) {
        display: block;
    }

    .text-table-container-title {
        h2 {
            justify-self: center;
            margin: 0 auto;
            max-width: 741px;

            @media(max-width: $tp-breakpoint-max) {
                max-width: 610px;
            }

            @media(max-width: $sp-breakpoint-max) {
                max-width: 221px;
            }
        }
    }

    .text-table {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: 1180px;

        .text-block {
            padding: 20px 50px;
            width: 25%;

            @media(max-width: $tp-breakpoint-max) {
                padding: 25px;
            }

            @media(max-width: $sp-breakpoint-max) {
                padding: 15px;
                width: 50%;
            }

            h4 {
                color: #000;
                font-family: Avenir, sans-serif;
                font-size: 19px;
                font-weight: 400;
                line-height: 26px;

                @media(max-width: $tp-breakpoint-max) {
                    font-size: 16px;
                    line-height: 24px;
                }

            }

            p {
                color: $text-gray;
                line-height: 1.5;
                margin-top: 1px;

            }
        }
    }
}