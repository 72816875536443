@import './styles/global-variables';

.rating-reviews-container {
    margin: 30px auto;
    max-width: 1200px;
    padding: 50px 0;

    .review-items {
        background-color: #faf9f8;
        border-left: 1px solid $light-orange;
        border-right: 1px solid $light-orange;
        border-top: 1px solid $light-orange;
        display: flex;
        padding: 59px;

        &:last-child {
            border-bottom: 1px solid $light-orange;
        }

        @media (max-width: $tp-breakpoint-max) {
            flex-direction: column;
        }

        @media (max-width: $sp-breakpoint-max) {
            padding: 25px;
        }
    }

    .rating-reviews-heading {
        margin-bottom: 30px;
        padding: 0 60px;

        @media (max-width: $sp-breakpoint-max) {
            padding: 0 25px;
        }

        h1,
        h2 {
            display: inline;
        }

        h2 {
            margin: 0;
        }

        .rating-reviews-stars {
            align-items: baseline;
            display: flex;
            justify-content: space-between;
            margin-top: 23px;
            max-width: 400px;

            @media (max-width: $sp-breakpoint-max) {
                flex-direction: column;
                margin-top: 10px;
            }

            .overall-rating {
                color: $text-gray;
                font-family: Avenir, sans-serif;
                font-size: 16px;
            }
        }

        .rating-reviews-sort {
            display: flex;
            justify-content: space-between;
            margin-top: 25px;

            @media (max-width: $sp-breakpoint-max) {
                flex-direction: column-reverse;
                margin-top: 36px;
            }

            .rating-reviews-btn {
                @include primary-button;
            }
        }
    }

    .reviews-load-more-btn {
        margin-top: 50px;
        text-align: center;

        button {
            @include golden-underline;

            background: transparent;
            border: 0;
            color: black;
            cursor: pointer;
            font-size: 16px;
            padding: 6px 0;
        }
    }
}
