@import './styles/global-variables';

.product-card {
    display: block;
    padding-top: 20px;
    text-align: center;

    a {
        color: $dark-gray;

        .image {
            max-width: 100%;
        }

        .event_image_click {
            margin-bottom: 20px;
        }

        .product-benefit {
            color: $golden;
            display: block;
            font-size: 14px;
            font-weight: normal;
            margin-bottom: 0;
            margin-top: 15px;
            padding: 0;
        }

        .product-name {
            display: block;
            font-size: 16px;
            font-weight: normal;
            margin-bottom: 1.5rem;
            margin-top: 6px;
            padding: 0 22px;

            @media screen and (device-aspect-ratio: 40 / 71) {
                padding: 0 5px;
            }

            h3 {
                font-size: 18px;
            }
        }
    }
}
