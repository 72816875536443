@import './styles/global-variables';

.step-info-block {
    background-image: url('https://images.ctfassets.net/a8l3ylu84syn/30gWnstSMn8bQIQPyEiLxz/5120b80278f4a89ae26a2d5f633565a3/block-bg.jpg?fm=webp&q=70');
    background-repeat: repeat;
    background-size: cover;
    box-shadow: $paper-box-shadow;
    color: #000;
    margin: 0 auto;
    max-width: $content-width-dt;
    padding: 45px 68px 30px 70px;
    position: relative;
    width: 100%;

    @media screen and (min-width: 0\0) {
        background-image: url('https://images.ctfassets.net/a8l3ylu84syn/30gWnstSMn8bQIQPyEiLxz/5120b80278f4a89ae26a2d5f633565a3/block-bg.jpg?fm=webp&q=70');
    }

    @media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
        background-image: url('https://images.ctfassets.net/a8l3ylu84syn/30gWnstSMn8bQIQPyEiLxz/5120b80278f4a89ae26a2d5f633565a3/block-bg.jpg?fm=webp&q=70');
    }

    @media (max-width: 1180px) {
        width: calc(100% - 68px);
    }

    @media (max-width: $tp-breakpoint-max) {
        padding: 36px 45px 25px;
    }

    @media (max-width: $sp-breakpoint-max) {
        padding: 31px 30px 17px;
        width: calc(100% - 30px);
    }

    &.pulled {
        background: #F2F3F0;
        margin: -120px auto auto;

        @media (max-width: $tp-breakpoint-max) {
            margin-top: -160px;
        }

        @media (max-width: $sp-breakpoint-max) {
            margin-top: -35px;
        }

        @media (min-width: $xl-breakpoint-min) {
            margin-top: -8.3333333vw;
        }
    }

    .special-tag {
        background: transparent linear-gradient(270deg, #a87c33 0%, #ad833b 4%, #f4e1a7 54%, #6f4617 100%) 0% 0% no-repeat padding-box;
        border-radius: 4px;
        font-style: normal;
        font-weight: 900;
        margin: auto;
        margin-bottom: 40px;
        margin-top: -20px;
        opacity: 0.8;
        padding: 2px 36px;
        text-align: center;
        width: 212px;

        p {
            margin: 11px;
        }
    }

    .block-image {
        display: block;
        height: auto;
        margin: 0 auto;
        max-width: 100%;
        @media (min-width: $tp-breakpoint-min) {
            margin-bottom: 10px;
        }
    }

    .text-block {
        .step-label {
            color: #000;
            font-family: Didot, serif;
            font-size: 1.75rem;
            font-weight: bold;
            line-height: 2.375rem;
            margin-bottom: 15px;

            @media (max-width: $tp-breakpoint-max) {
                font-size: 1.375rem;
                line-height: 1.75rem;
                margin-bottom: 25px;
            }

            @media (max-width: $sp-breakpoint-max) {
                line-height: 2rem;
                margin-bottom: 21px;
            }
        }

        h1 {
            margin-bottom: 0;
            margin-top: 0;
        }

        h2 {
            font-weight: 400;
            margin-bottom: 26px;
            margin-top: 30px;

            @media (max-width: $tp-breakpoint-max) {
                margin-bottom: 16px;
            }

            @media (max-width: $sp-breakpoint-max) {
                margin-bottom: 15px;
            }
        }

        ul {
            li {
                color: $text-gray;

                strong {
                    color: #000;
                }
            }
        }

        p {
            color: $text-gray;
            line-height: 1.6rem;
            margin-bottom: 20px;
            margin-top: 0;

            @media (min-width: $dt-breakpoint-min) {
                padding-right: 82px;
            }

            a {
                color: #815b30 !important;
                text-decoration: underline;
            }

            strong {
                color: #000;

                a {
                    color: #815b30 !important;
                }
            }
        }

        p,
        span {
            &.emphasized,
            &.emphasised {
                color: black;
                font-size: 19px;
                font-weight: bold;
                line-height: 1.36;
            }

            &.note {
                color: $golden;
                font-size: 15px;
            }
        }
    }
}

.no-wrapper {
    @include block-wrapper-common;
}
