@import './styles/global-variables';

.error-page {
    background-position: left top, right top;
    background-repeat: no-repeat, no-repeat;
    margin-top: 70px;
    padding: 0 7% 70px;
    text-align: center;
    width: 100%;

    h1,
    h2,
    p {
        font-size: 1.1875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.625rem;

        @media(max-width: $tp-breakpoint-max) {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    h2 {
        margin-top: 22px;
    }

    img {
        max-width: 294px;
        width: 100%;

        @media(max-width: $tp-breakpoint-max) {
            height: auto;
            max-width: 275px;
            padding: 0 20px;
        }
    }

    p {
        margin-top: 26px;

        a {
            color: $golden;
            text-decoration: underline;
        }
    }

    .link-block {
        margin-top: -19px;
    }
}
