@import './styles/global-variables';

.nixed-ingredients-wrapper {
    background-position: calc(50% - 740px) 50%;
    background-repeat: no-repeat;
    margin-bottom: 70px;
    padding-top: 20px;

    @media (max-width: $tp-breakpoint-max) {
        background-size: 0;
        margin-bottom: 55px;
    }

    @media (max-width: $sp-breakpoint-max) {
        margin-bottom: 42px;
    }

    .text-wrapper {
        padding: 0 40px;
        text-align: center;

        @media (max-width: $tp-breakpoint-max) {
            padding: 0 45px;
        }

        @media (max-width: $sp-breakpoint-max) {
            padding: 0 12px;
        }
    }
}

.nixed-ingredients {
    margin: 0 auto;
    max-width: 1080px;
    padding: 0 80px 3px;
    width: calc(100% - 68px);

    @media (max-width: $tp-breakpoint-max) {
        padding: 0;
        width: calc(100% - 68px);
    }

    h2 {
        margin: 0 0 30px;

        @media (max-width: $tp-breakpoint-max) {
            margin-bottom: 16px;
        }

        @media (max-width: $sp-breakpoint-max) {
            margin-bottom: 14px;
        }
    }

    .left-block {
        color: #000;
        display: inline-block;
        font-size: 1.1875rem;
        line-height: 1.625rem;
        margin: 0 9.5238% 45px 0;
        max-width: 400px;
        vertical-align: top;
        width: 47.619%;

        @media (max-width: $tp-breakpoint-max) {
            font-size: 1rem;
            line-height: 1.5rem;
            margin: 0 8.1967% 45px 0;
            width: 45.9016%;
        }

        @media (max-width: $sp-breakpoint-max) {
            display: block;
            margin-bottom: 18px;
            max-width: inherit;
            width: 100%;
        }
    }

    .right-block {
        color: $text-gray;
        display: inline-block;
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 0 0 45px;
        vertical-align: top;
        width: 42.8572%;

        @media (max-width: $tp-breakpoint-max) {
            font-size: 1rem;
            line-height: 1.5rem;
            width: 45.9016%;
        }

        @media (max-width: $sp-breakpoint-max) {
            display: block;
            margin-bottom: 37px;
            max-width: inherit;
            width: 100%;
        }
    }

    .ingredients-icons {
        max-width: 920px;
        width: 100%;
    }

    .read-more-wrapper {
        margin-bottom: 10px;
        text-align: center;
    }

    .read-more {
        @include gold-background;

        color: #000;
        display: inline-block;
        font-family: Avenir, sans-serif;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.4375rem;
        padding: 16px 32px;
        text-decoration: none;

        @media (max-width: $sp-breakpoint-max) {
            max-width: 200px;
        }
    }
}

.nixed-ingredients-icon {
    display: inline-block;
    margin-bottom: 55px;
    vertical-align: top;
    width: 20%;

    @media (max-width: $tp-breakpoint-max) {
        margin-bottom: 28px;
        width: 33.3333%;
    }

    @media (max-width: $sp-breakpoint-max) {
        width: 50%;
    }

    img {
        display: block;
        margin: 0 auto 11px;
    }

    p {
        color: #000;
        line-height: 1.5rem;
        margin: 0 auto;
        text-align: center;

        @media (max-width: $tp-breakpoint-max) {
            max-width: 184px;
        }
    }
}
