@import './styles/global-variables';

.subheader {
    background-color: #faf9f8;
    box-shadow: 0 1px 1px rgba(110, 107, 101, .2);
    color: $golden;
    display: flex;
    height: 41px;
    justify-content: space-between;

    @media (max-width: $sp-breakpoint-max) {
        height: 0;
    }

    .subheader-left {
        .label {
            @media (max-width: $sp-breakpoint-max) {
                display: none;
            }
        }
    }

    .subheader-right {
        align-items: center;
        display: flex;
        font-size: 13px;

        a {
            color: $golden;
        }

        span {
            cursor: pointer;
            font-size: 12px;
            margin-right: 50px;
        }

        @media (max-width: $sp-breakpoint-max) {
            display: none;
        }

        > * {
            margin-right: 20px;
        }
    }

    .chat-widget {
        background-color: #000;
        border-bottom-right-radius: 100px;
        border-top-right-radius: 100px;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        height: 100px;
        left: -1px;
        padding-left: 0;
        padding-top: 7px;
        position: fixed;
        text-transform: uppercase;
        top: 85%;
        transform: translateY(-50%);
        width: 58px;

        .chat-icon {
            align-items: center;
            background-color: #fff;
            border-radius: 4px;
            display: flex;
            height: 18px;
            justify-content: center;
            margin-top: 5px;
            position: relative;
            width: 30px;

            &::after {
                border-left: 2px solid transparent;
                border-right: 6px solid transparent;
                border-top: 8px solid #fff;
                bottom: -8px;
                content: '';
                display: block;
                height: 0;
                left: 6px;
                position: absolute;
                width: 0;
            }

            div {
                background-color: #000;
                border-radius: 1.5px;
                height: 5px;
                margin: 1px;
                width: 5px;
            }
        }
    }

    .popup-container {
        background-color: rgba(0, 0, 0, .8);
        display: block;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;

        .popup {
            height: 296px;
            margin: 0 auto;
            padding: 48px;
            position: relative;
            top: 50%;
            transform: translate(0, -50%);
            width: 830px;

            @media (max-width: 767px) {
                background-position: center bottom;
                height: 568px;
                margin: 0 20%;
                padding: 30px;
                padding-bottom: 50vw;
                width: auto;
            }

            @media (max-width: 650px) {
                margin: 0 10%;
            }

            @media (max-width: 400px) {
                margin: 0 2%;
                padding-bottom: 60vw;
            }

            p {
                color: #000;
                font-family: Didot, serif;
                font-size: 38px;
                margin: 0;

                @media (max-width: 767px) {
                    font-size: 31px;
                }
            }

            button {
                background-color: #000;
                border: 0;
                color: #fff;
                cursor: pointer;
                margin-right: 70px;
                margin-top: 30px;
                padding: 9px 22px;

                @media (max-width: 499px) {
                    margin-right: 10px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
