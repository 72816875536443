@import './styles/global-variables';

.ingredients-block-container {
    align-items: center;
    background-position: calc(50% - 740px) center, calc(50% + 850px) center;
    background-repeat: no-repeat, no-repeat;
    display: flex;
    flex-direction: column;
    padding-top: 90px;

    @media(max-width: $tp-breakpoint-max) {
        background-size: 0, 0;
    }

    @media(max-width: $sp-breakpoint-max) {
        background-size: 0, 0;
    }

    .ingredients-block-container-title {
        text-align: center;

        h2 {
            line-height: 1.35;
            margin: 0 0 30px;
            width: 741px;

            @media(max-width: $tp-breakpoint-max) {
                width: 610px;
            }

            @media(max-width: $sp-breakpoint-max) {
                width: 221px;
            }
        }
    }

    .center-ingredients {
        justify-content: center;
    }

    .ingredients-items-container {
        display: flex;
        flex-wrap: wrap;
        width: 1120px;

        @media(min-device-width: $tp2-breakpoint-max) and (max-device-width: 1100px) {
            width: 1023px;
        }

        @media(max-width: $tp-breakpoint-max) {
            width: 681px;
        }

        @media(max-width: $sp-breakpoint-max) {
            width: 100%;
        }

        .ingredients-block {
            display: flex;
            justify-content: center;
            margin-bottom: 25px;
            width: 20%;

            @media (max-width: $tp-breakpoint-max) {
                width: 33.3333%;
            }

            @media (max-width: $sp-breakpoint-max) {
                width: 50%;
            }

            a {
                width: 176px;

                img {
                    height: 241px;
                    width: 100%;

                    @media (max-width: $xs-breakpoint-max) {
                        width: 145px;
                    }
                }

                .ingredients-info {
                    margin: 0 auto;
                    text-align: center;

                    h2,
                    h3,
                    h4 {
                        color: #000;
                        font-family: Avenir, sans-serif;
                        font-size: 19px;
                        font-weight: 400;
                        line-height: 26px;
                        margin-bottom: 0;
                        width: 176px;

                        @media(max-width: $tp-breakpoint-max) {
                            font-size: 16px;
                            line-height: 24px;
                        }

                        @media(max-width: $sp-breakpoint-max) {
                            width: 160px;
                        }
                    }

                    p {
                        color: $text-gray;
                        line-height: 1.5;
                        margin-top: 1px;
                        width: 176px;

                        @media(max-width: $sp-breakpoint-max) {
                            width: 160px;
                        }
                    }
                }
            }
        }

        .ingredients-block-without-url {
            width: 33%;

            @media (max-width: $sp-breakpoint-max) {
                width: 50%;
            }

            .ingredients-without-url {
                width: 176px;

                img {
                    width: 100%;
                }

                .ingredients-info {
                    margin: 0 auto;
                    text-align: center;
                }
            }
        }
    }
}
