@import './styles/global-variables';

.sitemap-container {
    background-position: calc(50% - 760px) center, calc(50% + 760px) top;
    background-repeat: no-repeat, no-repeat;
    padding-bottom: 40px;
    padding-top: 60px;

    .sitemap-inner-container {
        margin: 0 auto;
        width: 1200px;

        @media(max-width: 1199px) {
            width: calc(100% - 68px);
        }

        @media(max-width: $sp-breakpoint-max) {
            margin-left: 30px;
        }

        h1 {
            font-style: normal;
            font-weight: bold;
            margin-bottom: 20px;

            @media(max-width: $tp-breakpoint-max) {
                margin-bottom: 5px;
                padding-left: 47px;
            }

            @media(max-width: $sp-breakpoint-max) {
                padding-left: 0;
            }
        }
    }

    .sitemap {
        border-top: 2px solid #000;
        display: flex;
        margin: 0 auto;

        @media(max-width: $sp-breakpoint-max) {
            flex-flow: column wrap;
        }

        h2 {
            font-size: 28px;
            margin-bottom: 0;
        }

        .long {
            display: flex;
            width: 33.33%;

            @media(max-width: $tp-breakpoint-max) {
                width: 50%;
            }

            @media(max-width: $sp-breakpoint-max) {
                width: 100%;
            }

            .sitemap-block {
                width: 100%;
            }
        }

        .normal-container {
            width: 66.66%;

            @media(max-width: $tp-breakpoint-max) {
                width: 50%;
            }

            @media(max-width: $sp-breakpoint-max) {
                width: 100%;
            }

            .normal {
                display: flex;
                flex-wrap: wrap;

                @media(min-width: $tp-breakpoint-max) {
                    flex-direction: column;
                    max-height: 1500px;
                }

                .sitemap-block {
                    width: 49%;

                    @media(max-width: $tp-breakpoint-max) {
                        width: 100%;
                    }
                }
            }
        }

        .sitemap-block {
            @media(max-width: $tp-breakpoint-max) {
                padding-left: 47px;
            }

            @media(max-width: $sp-breakpoint-max) {
                padding-left: 0;
                padding-right: 10px;
                padding-top: 32px;
                width: 100%;
            }

            .sitemap-item {
                color: #000;
                font-family: Didot, serif;
                font-size: 28px;
                font-weight: bold;
                line-height: 50.88px;

                @media(max-width: $tp-breakpoint-max) {
                    font-size: 26px;
                    line-height: 32px;
                }

                p {
                    font-size: 28px;
                    margin-bottom: 0;

                    @media(max-width: $tp-breakpoint-max) {
                        font-size: 26px;
                        margin-bottom: 10px;
                    }
                }

                ul {
                    list-style-type: none;
                    margin: 0;
                    padding-left: 0;

                    li {
                        margin-bottom: 0;

                        @media(max-width: $tp-breakpoint-max) {
                            margin-bottom: 15px;

                        }

                        a,
                        span {
                            color: $text-gray;
                            font-family: Avenir, sans-serif;
                            font-size: 1rem;
                            line-height: 24px;
                        }

                        a {
                            font-weight: normal;
                        }

                        span {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}