@import './styles/global-variables';

.product-listing-header {
    overflow: hidden;
    padding: 20px;
    position: relative;
    min-height: 530px;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        width: 1080px;

        @media (max-width: $tp2-breakpoint-max) {
            width: 82%;
        }

        @media (max-width: $tp-breakpoint-max) {
            width: $content-width-tb;
        }

        @media (max-width: $sp-breakpoint-max) {
            width: 100%;
        }

        .description {
            display: flex;
            position: relative;

            @media (max-width: $tp-breakpoint-max) {
                flex-direction: column;
            }

            .main-description {
                width: 100%;

                h1 {
                    margin: 0;

                    @media (max-width: $sp-breakpoint-max) {
                        font-size: 28px;
                    }
                }
            }

            .additional-description {
                color: $text-gray;
                padding: 0 6%;
                width: 100%;

                h2 {
                    color: $dark-gray;

                    @media (max-width: $sp-breakpoint-max) {
                        font-size: 26px;
                    }
                }

                p {
                    left: -131%;
                    position: relative;
                    top: 85px;
                    width: 200%;

                    @media (max-width: $tp-breakpoint-max) {
                        top: 236px;
                        width: 86%;
                    }

                    @media (max-width: $sp-breakpoint-max) {
                        top: 242px;
                    }
                }

                span {
                    p {
                        top: 380px;

                        @media (max-width: $sp-breakpoint-max) {
                            top: 493px;
                        }
                    }
                }
            }
        }
    }

    .left-image,
    .right-image {
        position: absolute;
        z-index: 1;

        @media (max-width: $tp-breakpoint-max) {
            display: none;
        }
    }

    .left-image {
        bottom: 0;
        left: calc(50% - 880px);
    }

    .right-image {
        bottom: 0;
        left: calc(50% + 580px);
    }

    .top-image {
        height: auto;
        width: 100%;
        object-fit: contain;

        @media (max-width: $tp-breakpoint-max) {
            @supports (aspect-ratio: 4 / 3) {
                aspect-ratio: 4 / 3;
            }

            @supports not (aspect-ratio: 4 / 3) {
                margin: 4rem 0 1rem;
            }
        }

        @media screen and (min-width: 0\0) {
            max-width: 1180px;
        }
    }
}

.paralink p a {
    text-decoration: underline;
}