@import './styles/global-variables';

.stickyBanner{
.web-links{
    justify-content: center;
    text-align:center;
    display:flex;
    flex-direction:row;
}
.web-links a img{
    height :45px;
}
.stickybannerFixed {
    bottom: 0;
    z-index: 39;
    justify-content: center;
}
.scrollButtonContainer {
    position: fixed;
    bottom: 0;
    z-index: 39;
    width:100%;
}
}
