@import './styles/global-variables';

.write-review {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    padding-top: 200px;

    .content {
        background-color: $light-gray;
        border: 1px solid $light-orange;
        max-width: 1200px;
        width: 1200px;

        @media(min-width: $dt-breakpoint-min) {
            h2 {
                font-size: 26px;
            }
        }

        @media(max-width: $tp-breakpoint-max) {
            width: 100%;
        }

        .error {
            color: #e41811;
            font-size: 14px;
            margin: 0;
            padding: 0;
        }

        .input-error {
            border: 1px solid #e41811;
        }

        .field-info {
            color: $golden;
            font-size: 14px;
            margin: 3px 0 0;
            padding: 0;
        }

        .accessiblity-container {
            .accessibility-hidden-element {
                display: none;
            }
        }

        .field-wrapper {
            display: flex;
            min-width: 480px;

            @media(max-width: $sp-breakpoint-max) {
                min-width: auto;
            }
        }

        .number-of-errors {
            margin: 30px 0 0 15%;

            @media (max-width: $sp-breakpoint-max) {
                margin-left: 8%;
            }
        }

        .write-review-footer {
            color: $golden;
            font-size: 14px;
            min-width: 480px;

            @media(max-width: $sp-breakpoint-max) {
                margin: 0;
                min-width: auto;
                text-align: center;
            }

            button,
            a {
                display: inline-block;
                margin: 40px 20px 10px 0;
                padding: 10px;
                text-align: center;
                width: 145px;

                @media(max-width: $sp-breakpoint-max) {
                    margin: 10px 0;
                    text-align: center;
                }
            }


            .footer-content {
                min-width: 480px;

                @media(max-width: $sp-breakpoint-max) {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    min-width: auto;
                }

                .links {
                    @media(max-width: $sp-breakpoint-max) {
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                    }

                    a {
                        color: $golden;
                        margin: 0 20px 0 0;
                        padding: 0;
                        text-align: left;
                        text-decoration: underline;
                        width: auto;
                    }
                }
            }

        }

        select {
            background-color: white;
            border: 1px solid $dark-gray;
            font-size: 18px;
            margin-right: 20px;
            padding: 5px;
            width: 160px;

            @media(max-width: $sp-breakpoint-max) {
                width: 130px;
            }
        }

        input,
        textarea {
            border: 1px solid $dark-gray;
            font-size: 18px;
            padding: 5px;
            width: 480px;

            @media(max-width: $tp-breakpoint-max) {
                width: 100%;
            }
        }

        .checkbox {
            margin-right: 50px;

            &::before {
                border: 1px solid $dark-gray;
                margin-right: 15px;
            }
        }

        .write-review-header {
            border-bottom: 1px solid $light-orange;
            display: flex;
            justify-content: space-between;
            padding: 0 15% 3% 10%;

            @media(max-width: $tp-breakpoint-max) {
                flex-direction: column-reverse;
                padding: 0;
            }

            .main-title {
                margin-right: 30px;
                max-width: 300px;
                min-width: 300px;
                position: relative;
                text-align: right;

                @media(max-width: $tp-breakpoint-max) {
                    max-width: 100%;
                    min-width: 100%;
                    text-align: center;
                }

                &::after {
                    color: $golden;
                    content: '\2193';
                    font-size: 30px;
                    height: 25px;
                    position: absolute;
                    right: 0;

                    @media(max-width: $tp-breakpoint-max) {
                        display: block;
                        margin-bottom: 10px;
                        position: relative;
                        top: -10px;
                    }
                }
            }

            .product-info {
                display: flex;

                @media(max-width: $tp-breakpoint-max) {
                    flex-direction: column;
                }


                .title-and-rating {
                    margin-right: 20px;
                    text-align: right;

                    @media(max-width: $tp-breakpoint-max) {
                        text-align: left;
                    }

                    h1 {
                        font-size: 34px;
                    }
                }

                img {
                    height: 130px;
                    margin-top: 30px;

                    @media(max-width: $tp-breakpoint-max) {
                        display: block;
                        height: auto;
                        margin: 30px auto;
                    }

                    @media(max-width: $tp-breakpoint-max) {
                        height: 150px;
                    }

                }
            }
        }

        .rating,
        .review,
        .information,
        .write-review-footer {
            border-bottom: 1px solid $light-orange;
            padding: 0 10%;

            @media(max-width: $sp-breakpoint-max) {
                padding: 0 8%;
            }

            > div {
                display: flex;
                margin-bottom: 30px;

                @media(max-width: $tp-breakpoint-max) {
                    flex-direction: column;
                }

                > .field-label {
                    font-size: 20px;
                    margin-bottom: 20px;
                    margin-right: 20px;
                    max-width: 300px;
                    min-width: 300px;
                    text-align: right;

                    @media(max-width: $tp-breakpoint-max) {
                        font-size: 18px;
                        margin-bottom: 10px;
                        max-width: auto;
                        min-width: auto;
                        text-align: left;
                    }
                }

                .required-field {
                    &::before {
                        content: '*';
                        margin-right: 5px;
                    }
                }
            }

            h2 {
                margin-bottom: 50px;
                max-width: 300px;
                min-width: 300px;
                text-align: right;

                @media(max-width: $tp-breakpoint-max) {
                    max-width: 100%;
                    min-width: 100%;
                    text-align: left;
                }
            }

            fieldset {
                border: 0;

                legend {
                    float: left;
                    font-size: 20px;
                    margin-bottom: 20px;
                    margin-right: 20px;

                    &::before {
                        content: '*';
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .secondary-button {
        @include secondary-button;
        padding: 10px;
        width: 130px;
    }

    .primary-button {
        @include primary-button;
        padding: 10px;
        width: 130px;
    }

    .underage {
        text-align: center;

        p {
            margin: 0;
            padding: 15px;
        }
    }
}
