@import './styles/global-variables';

.modal {
    .modal-overlay {
        background-color: rgba(0, 0, 0, .25);
        display: flex;
        height: 100vh;
        justify-content: center;
        left: 0;
        overflow: auto;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 20;
    }

    .modal-content {
        background-color: white;
        height: max-content;
        padding: 50px;
        position: relative;
        top: 300px;

        @media (max-width: $sp-breakpoint-max) {
            padding: 30px;
            top: 0;
        }

        .close {
            cursor: pointer;
            height: 32px;
            position: absolute;
            right: 31px;
            top: 36px;
            width: 32px;

            @media (max-width: $sp-breakpoint-max) {
                right: 23px;
                top: 27px;
            }

            &:hover {
                opacity: 1;
            }

            &::before,
            &::after {
                background-color: #80592e;
                content: ' ';
                height: 23px;
                left: 10px;
                position: absolute;
                width: 2px;
            }

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }
        }

        span {
            color: $text-gray;
        }
    }
}
