$golden: #815b30;
$dark-gray: #383838;
$medium-gray: #c5c5c5;
$light-gray: #faf9f8;
$text-gray: #575757;
$dark-green: #0e5329;
$light-orange: #ece7e1;

$paper-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
$gold-focus: rgba(129, 91, 48, 0.3);

$sp-breakpoint-max: 767px;

$tp-breakpoint-min: 768px;
$tp-breakpoint-max: 991px;
$tp2-breakpoint-max: 1024px;

$dt-breakpoint-min: 992px;

$xs-breakpoint-max: 320px;
$xl-breakpoint-min: 1441px;

$content-width-dt: 1080px;
$content-width-tb: 700px;
$content-width-sp: 340px;

$max-acc-item-height: 2600px;

$header-h-dt: 130px;
$header-h-tb: 138px;
$header-h-sp: 66px;

@mixin circled-arrow {
    background-color: transparent;
    border-color: black;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    height: 40px;
    outline: none;
    position: relative;
    width: 40px;

    &:focus {
        background-color: $gold-focus;
    }

    &::before {
        background-color: black;
        content: '';
        height: 2px;
        left: 13px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
    }

    &::after {
        border-right: 2px solid black;
        border-top: 2px solid black;
        box-sizing: border-box;
        color: inherit;
        content: '';
        height: 9px;
        left: 15px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 9px;
    }

    &.slick-disabled {
        &::before {
            opacity: 0.25;
        }

        &::after {
            opacity: 0.25;
        }
    }
}

@mixin circled-arrow-simple {
    background-color: transparent;
    border-color: black;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    height: 40px;
    position: relative;
    width: 40px;

    &:focus {
        background-color: $gold-focus;
    }

    &::before {
        border-right: 2px solid black;
        border-top: 2px solid black;
        color: inherit;
        content: '';
        height: 10px;
        left: 11px;
        position: absolute;
        top: 14px;
        transform: rotate(45deg);
        width: 10px;
    }
}

@mixin primary-button {
    background-color: black;
    border: 1px solid #707070;
    color: white;
    cursor: pointer;
    padding: 14px;
}

@mixin secondary-button {
    background-color: white;
    border: 1px solid black;
    color: black;
    cursor: pointer;
    padding: 12px;
}

@mixin visually-hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

@mixin focusable-item {
    outline: 2px dotted $medium-gray;
}

@mixin golden-underline {
    $darker: #533718;
    $shine: #ecd38b;
    $lighter: #94602f;

    position: relative;

    &::before {
        bottom: -3px;
        content: '';
        display: block;
        height: 6px;
        left: 0;
        position: absolute;
        width: 100%;
    }

    &::before {
        background-image: linear-gradient(
            to right,
            $darker 0%,
            $shine 20%,
            $lighter 48%,
            $darker 52%,
            $shine 70%,
            $lighter 100%
        );
        background-position-x: 100%;
        background-size: 200% 100%;
        transition: none;
    }

    &:hover {
        &::before {
            background-position-x: 0;
            transition: background-position-x ease-out 0.33s;
        }
    }
}

@mixin gold-background {
    $first-color: #c49543;
    $second-color: #d5b965;
    $third-color: #c49241;
    background-image: linear-gradient(
        90deg,
        $first-color 0%,
        $second-color 25%,
        $third-color 45%,
        $second-color 60%,
        $first-color 100%
    );
    background-position-x: 100%;
    background-size: 200% 100%;
    font-weight: 700;
    color: #000;

    &:hover {
        background-position-x: 0;
        transition: background-position-x ease-out 0.93s;
    }
}

@mixin block-wrapper-common {
    background-repeat: no-repeat;
    padding-bottom: 70px;

    @media (max-width: $tp-breakpoint-max) {
        background-size: 0, 0;
        padding-bottom: 55px;
    }

    @media (max-width: $sp-breakpoint-max) {
        padding-bottom: 40px;
    }
}

@mixin no-circle-right-arrow-icon {
    background-color: white;
    color: $golden;
    display: inline-block;
    height: 23px;
    position: absolute;
    width: 23px;

    &::before {
        border-top: 1.5px solid rgba($golden, 0.8);
        content: '';
        height: 1px;
        left: 5px;
        position: absolute;
        top: 15px;
        width: 8px;
    }

    &::after {
        border-right: 1px solid $golden;
        border-top: 1px solid $golden;
        content: '';
        height: 6px;
        left: 8px;
        position: absolute;
        top: 12.5px;
        transform: rotate(45deg);
        width: 6px;
    }
}

@mixin circle-plus-icon {
    background-color: white;
    border: 1.5px solid rgba($golden, 0.7);
    border-radius: 50%;
    color: $golden;
    display: inline-block;
    height: 23px;
    position: absolute;
    right: 36px;
    width: 23px;

    &::before {
        border-top: 1.5px solid rgba($golden, 0.8);
        content: '';
        height: 1px;
        left: 6px;
        position: absolute;
        top: 10px;
        width: 8px;
    }

    &::after {
        border-top: 1.5px solid rgba($golden, 0.8);
        content: '';
        height: 1px;
        left: 6px;
        position: absolute;
        top: 10px;
        transform: rotate(90deg);
        width: 8px;
    }
}
@mixin circle-minus-icon {
    background-color: white;
    border: 1.5px solid rgba($golden, 0.7);
    border-radius: 50%;
    color: $golden;
    display: inline-block;
    height: 23px;
    position: absolute;
    right: 36px;
    width: 23px;

    &::before {
        border-top: 1.5px solid rgba($golden, 0.8);
        content: '';
        height: 1px;
        left: 6px;
        position: absolute;
        top: 10px;
        width: 8px;
    }
}
