@import './styles/global-variables';

.product-carousel {
    background-position: calc(50% - 740px) center, calc(50% + 740px) center;
    background-repeat: no-repeat, no-repeat;
    contain-intrinsic-size: 800px;
    content-visibility: auto;
    margin-bottom: 70px;

    @media(max-width: 1150px) {
        background: none !important;
    }

    @media (max-width: $tp-breakpoint-max) {
        background-size: 0, 0;
        margin-bottom: 55px;
    }

    @media (max-width: $sp-breakpoint-max) {
        margin-bottom: 40px;
    }

    .slides-container {
        background-image: url('https://images.ctfassets.net/a8l3ylu84syn/30gWnstSMn8bQIQPyEiLxz/5120b80278f4a89ae26a2d5f633565a3/block-bg.jpg?fm=webp&q=70');
        box-shadow: $paper-box-shadow;
        margin: 0 auto;
        max-width: $content-width-dt;
        overflow: hidden;
        padding: 50px 0;
        text-align: center;

        @media screen and (min-width: 0\0) {
            background-image: url('https://images.ctfassets.net/a8l3ylu84syn/30gWnstSMn8bQIQPyEiLxz/5120b80278f4a89ae26a2d5f633565a3/block-bg.jpg?fm=webp&q=70');
        }

        @supports (-webkit-hyphens: none) {
            background-image: url('https://images.ctfassets.net/a8l3ylu84syn/30gWnstSMn8bQIQPyEiLxz/5120b80278f4a89ae26a2d5f633565a3/block-bg.jpg?fm=webp&q=70');
        }

        @media(max-width: 1150px) {
            margin: 0 5%;
        }

        h2 {
            margin: 0 5% 35px;
        }

        .slides {
            width: 100.5%;

            .link-0 {
            }

            .link {
                display: block;

                @media(max-width: $tp-breakpoint-min) {
                    padding-bottom: 0;
                }

                .image {
                    margin: 0 auto;
                    margin-bottom: 20px;
                }

                .rating-stars {
                    display: block;
                    font-size: 15px;
                    margin: 20px 0 0;
                    text-align: center;

                    .rating-numbers {
                        color: $text-gray;
                        font-size: .75rem;
                        line-height: 1rem;
                        margin: 0;
                        width: 100%;
                    }
                }

                .product-benefit {
                    color: $golden;
                    display: block;
                    font-size: .875rem;
                    font-weight: 400;
                    margin: 35px 0 0 0;
                }

                .product-name {
                    color: #000;
                    display: block;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    margin: 4px 0;
                    padding: 15px 22px 0px 22px;
                }
            }
        }

        .slick-slider {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .slick-prev,
            .slick-next {
                @include circled-arrow;

                color: black;
                flex-wrap: wrap;
                position: relative;
                top: -8px;
                transform: none;
            }

            .slick-list {
                flex-basis: 100%;
                margin-bottom: 30px;
                width: 100%;
            }

            .slick-prev {
                order: 2;
                transform: rotate(180deg);

                @media(max-width: $sp-breakpoint-max) {
                    left: 13px;
                    position: absolute;
                    top: 200px;
                    z-index: 2;
                }
            }

            .slick-next {
                order: 4;
            }

            @media(max-width: $sp-breakpoint-max) {
                .slick-prev,
                .slick-next {
                    position: absolute;
                    top: 100px;
                }

                .slick-prev {
                    left: 20px;
                }

                .slick-next {
                    right: 20px;
                }
            }

            .slick-dots {
                margin: 0 20px;
                order: 3;
                position: static;
                width: auto;

                li {
                    height: 12px;
                    width: 12px;

                    button {
                        background-color: #c5c5c5;
                        border: 0;
                        border-radius: 9px;
                        height: 10px;
                        padding: 6px;
                        width: 10px;
                    }
                }

                .slick-active {
                    button {
                        background-color: #000;
                    }
                }
            }
        }
    }
}
