@import './styles/global-variables';

.country-selector {
    font-family: Avenir, sans-serif;
    font-weight: 400;
    padding: 0 3vw;

    &:focus {
        @include focusable-item;
      }

    .label {
        background-color: transparent;
        border: 0;
        color: $golden;
        cursor: pointer;
        display: inline-block;
        font-size: 13px;
        line-height: 1.5rem;
        padding: 10px 30px 8px 20px;
        position: relative;
        z-index: 101;

        @media (max-width: $tp-breakpoint-max) {
            z-index: 0;
          }

        &::after {
            border: solid $golden;
            border-width: 0 1px 1px 0;
            content: '';
            display: block;
            height: 6px;
            position: absolute;
            right: 15px;
            top: calc(50% - 6px);
            transform: rotate(45deg);
            width: 6px;
          }
      }

    &.open {
        .label {
            background-color: white;
            box-shadow: 0 -5px 5px rgba(110, 107, 101, 0.5);

            &::before {
                /* patch for the shadow gap between label and wall */
                bottom: -2px;
                box-shadow: -3px 0 3px rgba(110, 107, 101, 0.5);
                content: "";
                display: block;
                height: 7px;
                left: 1px;
                position: absolute;
                width: 1px;
                z-index: -1;
              }
          }

        .dropdown-menu {
            animation: fadeIn 300ms forwards;
            box-shadow: 0 1px 5px rgba(110, 107, 101, 0.5);
            display: block;
          }
  }

    .dropdown-menu {
        background-color: white;
        color: $dark-gray;
        display: none;
        padding: 0 5vw;
        position: absolute;
        width: calc(100% - 84px);
        z-index: 601;

        &.out-of-viewport {
            @media (min-width: $dt-breakpoint-min) {
                max-height: 100%;
                overflow-y: scroll;
                position: fixed;
                top: 41px;
              }
          } 

        .dropdown-menu-header {
            border-bottom: 2px solid black;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 40px 0 20px;

            @media (max-width: $sp-breakpoint-max) {
                padding-top: 20px;
            }

            .select-country {
                font-family: Avenir, sans-serif;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                margin: 0;
                padding: 0;

                @media (max-width: $tp-breakpoint-min) {
                    padding-bottom: 2vw;
                  }

                @media (max-width: $sp-breakpoint-max) {
                    padding-bottom: 0;
                }
      }

            .mobile-close-icon {
                display: none;
                height: 15px;

                @media (max-width: $sp-breakpoint-max) {
                    display: block;
                }

                .line {
                    background-color: #815b30;
                    display: block;
                    height: 2px;
                    width: 15px;

                    &:nth-child(1) {
                        margin: 0 auto;
                        transform: translateY(12px) rotate(45deg);
                    }

                    &:nth-child(2) {
                        margin: 18px auto;
                        transform: translateY(-8px) rotate(-45deg);
                    }
                  }
            }
      }

        .country-list {
            -ms-flex-wrap: wrap;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .column {
                flex: 0 0 25%;
                max-width: 25%;
                padding-left: 0;
                padding-right: 15px;
                position: relative;
                width: 100%;

                @media (max-width: $tp-breakpoint-max) {
                    flex: 0 0 50%;
                    max-width: 50%;
                  }
                @media (max-width: $sp-breakpoint-max) {
                    flex: 0 0 100%;
                    max-width: 100%;
                  }
            }

            ul {
                list-style-type: none;
                padding: 0;

                li {
                    margin-bottom: 9px;

                    a {
                        color: #815b30;
                        display: inline-block;
                        padding-bottom: 5px;
                      }

                    &.current {
                        a {
                            @include golden-underline;
                          }
                      }
                  }
              }
          }
      }
}
