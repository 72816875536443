@import './styles/global-variables';

/*
    Unlike others, this component css is written mobile-first (using min-width media queries),
    because here the desktop is the exception (from a normal accordion behaviour, in mob and tab)
*/

$border-col: #ede9e3;

.nixed-accordion-holder {
    background-position: calc(50% - 740px) 125px;
    background-repeat: no-repeat;

    .title-block {
        a {
            @include golden-underline;
            display: inline-block;
        }

        h2 {
            margin: 0 auto 55px;
            max-width: 340px;
            text-align: center;

            @media (min-width: $tp-breakpoint-min) {
                max-width: 610px;
            }

            @media (min-width: $dt-breakpoint-min) {
                max-width: 750px;
            }
        }
    }

    .accordion-wrapper {
        margin: auto;
        width: 100%;

        @media screen and (min-width: $dt-breakpoint-min) {
            max-width: 1200px;
        }

        .accordion {
            background-color: $light-gray;
            border: 1px solid $border-col;
            padding: 0 10px;
            position: relative;

            @media (min-width: $tp-breakpoint-min) {
                padding: 0 35px;
            }

            @media (min-width: $dt-breakpoint-min) {
                display: flex;
                flex-direction: row;
                padding: 17px 40px 40px 75px;

                &::before {
                    border-top: 1px solid $border-col;
                    content: '';
                    left: 0;
                    position: absolute;
                    top: 77px;
                    width: 100%;
                }
            }

            .accordion-item {
                padding-right: 6px;

                @media (max-width: $tp-breakpoint-max) {
                    border-bottom: 1px solid $border-col;
                }

                @media (min-width: $dt-breakpoint-min) {
                    width: 16.666%;
                }

                .item-tab {
                    padding-left: 35px;
                    position: relative;

                    @media (min-width: $dt-breakpoint-min) {
                        cursor: default;
                        padding-left: 0;
                    }

                    h3 {
                        margin: 22px 0;

                        @media (min-width: $dt-breakpoint-min) {
                            margin: 18px 0;
                        }
                    }

                    .item-icon {
                        @include circled-arrow-simple;

                        position: absolute;
                        right: 0;
                        top: 14px;

                        @media (min-width: $tp-breakpoint-min) {
                            right: 35px;
                        }

                        @media (min-width: $dt-breakpoint-min) {
                            display: none;
                        }

                        &.up {
                            transform: rotate(-90deg);
                        }

                        &.down {
                            transform: rotate(90deg);
                        }
                    }
                }

                .text-block {
                    line-height: 1.45;
                    padding-bottom: 25px;
                    padding-top: 25px;
                    word-break: break-word;

                    ul {
                        list-style: none;
                        padding-left: 0;
                    }

                    @media (min-width: $dt-breakpoint-min) {
                        columns: auto;
                        max-width: 90%;
                    }

                    @media (min-width: $tp-breakpoint-min) and (max-width: $tp-breakpoint-max) {
                        columns: 2;
                    }

                    @media (max-width: $tp-breakpoint-max) {
                        padding-left: 35px;
                    }
                }
            }

            .item-hidden {
                @media (min-width: $dt-breakpoint-min) {
                    max-height: $max-acc-item-height;
                }
            }
        }
    }

    .footnote {
        clear: both;
        margin: auto;
        padding: 40px 45px;

        @media (min-width: $tp-breakpoint-min) {
            padding: 40px 75px;
            text-align: center;
        }

        @media (min-width: $dt-breakpoint-min) {
            max-width: 870px;
            padding: 40px 0;
        }
    }
}
