@import './styles/global-variables';

.filters {
    display: flex;
    position: relative;

    @media (max-width: $sp-breakpoint-max) {
        display: flex;
        flex-direction: column;
        margin: 30px 0;
    }

    .filter-selector {
        border: 1px solid $dark-gray;
        border-top: 4px solid $dark-gray;
        cursor: pointer;
        margin: 0 40px 0 0;
        padding: 10px;
        position: relative;
        width: 190px;

        @media(max-width: $tp-breakpoint-max) {
            margin: 0 20px 0 0;
        }

        &::after {
            border: solid black;
            border-width: 0 1px 1px 0;
            content: '';
            display: inline-block;
            padding: .2vw;
            position: absolute;
            right: 15px;
            top: 12px;
            transform: rotate(45deg);
        }

        &::before {
            border: solid black;
            border-width: 0 1px 1px 0;
            content: '';
            display: inline-block;
            left: -25px;
            padding: .2vw;
            position: absolute;
            top: 12px;
            transform: rotate(-45deg);

            @media(max-width: $tp-breakpoint-max) {
                left: -15px;
            }
        }

        &:first-child {
            &::before {
                display: none;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        .filter-options {
            background-color: white;
            border: 1px solid black;
            cursor: default;
            left: -1px;
            padding: 5px;
            position: absolute;
            top: 37px;
            width: 220px;
            z-index: 2;

            &.hidden {
                display: none;
            }

            .option {
                margin: 10px 0;

                input {
                    cursor: pointer;
                    margin-right: 10px;
                }

                label {
                    display: flex;
                    padding: 5px;

                    &:hover {
                        color: black;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .filter-type {
        border-top: 1px solid $dark-gray;
        padding: 10px 0 4px;
        position: relative;

        &::after {
            border: solid black;
            border-width: 0 1px 1px 0;
            content: '';
            display: inline-block;
            margin-left: .8vw;
            padding: 2px;
            position: absolute;
            right: 10px;
            top: 10px;
            transform: rotate(45deg);
        }

        &.active {
            border-top: 2px solid black;
        }

    }

    .filter-1 {
        order: 1;
    }

    .filter-2 {
        order: 3;
    }

    .filter-0 {
        order: 2;
    }

    .mobile-filter-options {
        color: $text-gray;

        > div {
            border-bottom: 1px solid lightgray;
            margin: 8px;
            padding: 5px;
            position: relative;

            &:last-of-type {
                border: 0;
            }
        }

        &.hidden {
            height: 0;
            opacity: 0;
        }
    }
}

.active-filters {
    display: inline;

    > div {
        background-color: lightgrey;
        display: inline-block;
        font-size: 12px;
        margin: 5px 8px 5px 0;
        padding: 10px 10px 10px 5px;
    }

    .remove-filter {
        height: 10px;
        width: 10px;

        &::before {
            content: '\2715';
            cursor: pointer;
            padding: 5px;
        }
    }
}
