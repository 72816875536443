@import './styles/global-variables';

@media (max-width: $tp-breakpoint-max) {
    .breadcrumbs-hidden {
        visibility: hidden;
    }
}

.breadcrumbs {
    background-color: rgba(255, 255, 255, 1);
    font-size: 12px;
    line-height: 1.5;
    padding: 66px 20px 8px 60px;
    position: absolute;
    top: 81px;
    width: 100%;
    z-index: -1;

    @media (max-width: 1390px) {
        left: -204px;
        width: 141%;    
    }
    @media (max-width: $tp-breakpoint-max) {
        left: 0;
        padding: 4px 0 0;
        top: $header-h-tb;
        z-index: 5;
    }

    @media (max-width: $sp-breakpoint-max) {
        left: 0;
        padding: 18px 0 0;
        top: $header-h-sp;
        z-index: 5;
    }

    ul {
        left: 200px;
        position: relative;
        margin: 0;
        padding: 0;

        @media (max-width: $tp-breakpoint-max) {
            padding: 15px 75px;
            left: 0;
        }

        @media (max-width: $sp-breakpoint-max) {
            padding: 15px 30px;
        }

        li {
            display: inline;
            margin-right: 30px;
            position: relative;

            &::before {
                border: solid $golden;
                border-width: 0 1px 1px 0;
                content: '';
                display: inline-block;
                margin-left: -20px;
                padding: 2px;
                position: absolute;
                top: 5px;
                transform: rotate(-45deg);
            }

            &:first-of-type {
                &::before {
                    content: none;
                }
            }

            a {
                color: $golden;
            }
        }
    }
}
