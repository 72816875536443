@import './styles/global-variables';

.header {
    height: $header-h-dt;
    position: relative;
    z-index: 10;
    min-height: 66px;

    @media (min-width: $dt-breakpoint-min) {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
    }

    @media (max-width: 1390px) {
        position: relative;
        top: -211px;
        width: 100%;
        z-index: 100;
    }

    @media (max-width: $tp-breakpoint-max) {
        height: $header-h-tb;
        top: unset;
    }

    @media (max-width: $sp-breakpoint-max) {
        background-color: white;
        box-shadow: 0 1px 3px rgba(110, 107, 101, 0.2);
        height: $header-h-sp;
    }

    .logo-link {
        border-radius: 50%;
        display: block;
        left: 43px;
        position: absolute;
        top: 44px;
        z-index: 10;

        @media (max-width: 1400px) {
            box-shadow: 0 7px 6px -4px rgba(0, 0, 0, 0.15);
            left: 50%;
            top: 41px;
            transform: translateX(-50%);
        }

        @media (max-width: $sp-breakpoint-max) {
            height: 88px;
            top: -10px;
            width: 88px;
        }
    }

    .logo {
        left: -17px;
        position: absolute;

        @media (max-width: $tp2-breakpoint-max) {
            left: 318px;
        }

        @media (max-width: $tp-breakpoint-max) {
            left: -60px;
            top: -4px;
        }

        @media (max-width: $sp-breakpoint-max) {
            left: -12px;
            top: 12px;
        }
    }

    .is-open {
        display: none;
    }
}