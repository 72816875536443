.box {
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    padding: 10px;
    position: relative;

    .disabled & {
        opacity: .4;
    }

    .error & {
        border: 1px solid red;
    }

    .checked & {
        &::before {
            content: '\2714';
            position: absolute;
            top: 0;
        }
    }
}

.checkbox {
    align-items: center;
    cursor: pointer;
    display: inline-flex;

    label {
        margin: 0 10px;
    }

    .required {
        &::before {
            content: '*';
            margin-right: 5px;
        }
    }
}
