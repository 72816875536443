@import './styles/global-variables';

.modal {
    .modal-content {
        width: 70%;

        @media (max-width: $tp-breakpoint-max) {
            font-size: 16px;
            margin-bottom: 10px;
            text-align: left;
            width: 90%;
        }

        .centered-content {
            text-align: center;
        }

        .modal-content-wrapper {
            display: flex;

            @media (max-width: $tp-breakpoint-max) {
                flex-direction: column;
            }

            .recommend,
            .user-location {
                color: $golden;
                font-size: 14px;
            }

            .user-info {
                border-right: 2px solid $light-gray;
                padding: 10px;
                width: 100%;

                @media (max-width: $tp-breakpoint-max) {
                    padding: 0;
                }

                .nickname {
                    color: $golden;
                    font-size: 14px;
                    margin: 0;

                    @media (max-width: $sp-breakpoint-max) {
                        margin-top: 10px;
                    }
                }

                .user-location {
                    color: $golden;
                    font-size: 14px;
                    margin: 0;
                }

                .current-date {
                    color: $golden;
                    font-size: 14px;
                }

                .rating-stars {
                    margin: 10px 0;
                }

                @media (min-width: $dt-breakpoint-min) {
                    width: 40%;

                    .rating-stars,
                    .current-date {
                        display: none;
                    }

                    .nickname {
                        color: black;
                        font-size: 16px;
                    }
                }
            }

            .review-preview {
                overflow-wrap: break-word;
                padding-left: 60px;
                width: 100%;

                @media (max-width: $tp-breakpoint-max) {
                    padding-left: 0;
                }

                .error {
                    color: red;
                }

                p {
                    color: $text-gray;
                }

                h4 {
                    font-size: 18px;
                    font-weight: normal;
                    margin: 10px 0;
                    position: relative;

                    span {
                        bottom: 34px;
                        color: $golden;
                        font-size: 16px;
                        font-weight: normal;
                        position: absolute;
                        right: 0;

                        @media (max-width: $tp-breakpoint-max) {
                            display: none;
                        }
                    }
                }

                .rating-stars {
                    @media (max-width: $tp-breakpoint-max) {
                        display: none;
                    }
                }

                .recommend {
                    color: $golden;
                    font-size: 14px;
                }

                .buttons {
                    @media (max-width: $tp-breakpoint-max) {
                        display: flex;
                        justify-content: center;
                        margin-top: 30px;
                    }

                    @media (max-width: $sp-breakpoint-max) {
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        margin-top: 30px;
                    }

                    button {
                        margin-bottom: 20px;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}
