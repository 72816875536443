@import './styles/global-variables';

.product-line-section {
    margin: auto;
    max-width: 1230px;
    padding-bottom: 70px;

    .product-line-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .product-line {
            align-items: center;
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 31%;

            a {
                background-image: url('https://images.ctfassets.net/a8l3ylu84syn/30gWnstSMn8bQIQPyEiLxz/5120b80278f4a89ae26a2d5f633565a3/block-bg.jpg?fm=webp&q=70');
                background-position: center;
                background-repeat: repeat;
                box-shadow: $paper-box-shadow;
                padding: 20px 0;
                width: 100%;

                @media (max-width: 767px) {
                    padding: 11px 0;
                }

                p {
                    color: black;
                    font-family: Didot, serif;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: bold;
                    margin: 0;
                }

                img {
                    @media (max-width: $tp-breakpoint-max) {
                        height: 211px;
                        width: 220px;
                    }
                }
            }

            @media screen and (max-width: $content-width-tb) {
                margin: 2.5% auto;
                width: 95%;
            }
        }
    }

    .product-line-title {
        h2 {
            font-size: 46px;
            font-weight: normal;
            text-align: center;

            @media (max-width: $tp-breakpoint-max) {
                font-size: 40px;
            }

            @media (max-width: $sp-breakpoint-max) {
                font-size: 28px;
            }
        }
    }

    @media (max-width: 1180px) {
        width: calc(100% - 68px);
    }

    @media (max-width: 767px) {
        padding-bottom: 0;
        width: calc(100% - 30px);
    }
}
