@import './styles/global-variables';

@media (max-width: $tp-breakpoint-max) {
    .apply-background {
        background-color: rgba(0, 0, 0, 0.2);
        display: block !important;
    }

    .overlay-wrapper {
        bottom: 0;
        display: none;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 8;
    }
}

.mobile-navigation {
    font-size: 16px;
    position: absolute;
    top: 139px;
    width: 100%;
    z-index: 1;

    @media (max-width: $sp-breakpoint-max) {
        top: 67px;
    }

    &.closed {
        opacity: 0;
        transition: opacity 250ms, width 250ms;
        width: 0;

        .mobile-navigation-sidebar {
            margin-left: -100vw;
            transition: margin-left 250ms;
        }
    }

    &.open {
        opacity: 1;
        transition: opacity 100ms;
        z-index: 600;

        .mobile-navigation-sidebar {
            border-bottom: 1px solid #ece7e1;
            margin-left: 0;
            transition: margin-left 250ms;

            .top-navigation-items {
                margin-bottom: 0;
                margin-top: 0;

                :last-child {
                    ul {
                        li.link-listing {
                            border-bottom: 1px solid $light-orange;

                            .link {
                                border-bottom: 0;
                            }
                        }

                        .explore-all-link {
                            a {
                                color: $golden;
                                display: inline-block;
                                font-size: 12px;
                                padding: 10px 0;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }

    ul {
        list-style: none;
        padding: 0;
    }

    .mobile-navigation-sidebar {
        background-color: white;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 56%;

        @media (max-width: $sp-breakpoint-max) {
            width: 84%;
        }

        .mobile-links {
            background-color: #faf9f8;
            cursor: pointer;
            margin-bottom: 0;
            margin-top: 0;
            padding: 23px;

            .mobile-link {
                color: $dark-gray;
                font-family: 'Didot', serif;
                font-size: 14px;
                font-weight: 700;
                margin-left: 38px;
                padding-bottom: 13px;
                padding-top: 3px;
                text-transform: uppercase;

                @media (max-width: $sp-breakpoint-max) {
                    margin-left: 10px;
                }

                a {
                    color: $dark-gray;
                    display: inline-block;
                    font-size: 14px;
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .icon {
            top: 22px;
        }

        .collapsed-icon,
        .link-icon {
            @include circle-plus-icon;

            font-size: 0;
            right: 31px;
        }

        .expanded-icon,
        .link-icon {
            @include circle-minus-icon;

            font-size: 0;
        }

        .expanding-item {
            border-top: 1px solid #f1ebe4;
            margin-top: -1px;
            position: relative;

            h3 {
                background-color: $light-gray;
                border-bottom: 1px solid #ece7e1;
                color: $dark-gray;
                cursor: pointer;
                display: block;
                font-size: 14px;
                font-weight: bold;
                margin: 0;
                padding: 4vw 8vw;
                text-transform: uppercase;

                @media (max-width: $sp-breakpoint-max) {
                    padding: 7vw 8vw;
                }
            }

            ul {
                background-color: white;
                padding: 0;
            }

            li {
                margin: 5vw 0;
                padding-left: 8vw;
                text-transform: none;

                a {
                    color: $dark-gray;
                }

                li {
                    padding-left: 0;
                }

                &:last-child {
                    @media (max-width: $sp-breakpoint-max) {
                        margin-bottom: 9vw;
                    }
                }

                @media (max-width: $sp-breakpoint-max) {
                    margin: 8vw 0;
                }
            }

            &.collapsed {
                ul {
                    display: none;
                }
            }

            &.expanded {
                ul {
                    display: block;
                }

                .category {
                    .category-link {
                        cursor: pointer;
                        display: inline-block;
                        width: 100%;
                    }

                    .popup-container {
                        background-color: rgba(0, 0, 0, 0.2);
                        display: none;
                        height: 100vh;
                        left: 0;
                        position: fixed;
                        top: 0;
                        width: 100vw;
                        z-index: 1;

                        &.open {
                            display: block;
                        }
                    }

                    .category-popup {
                        background-color: white;
                        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
                        display: none;
                        left: 25px;
                        padding: 0 15px;
                        padding-bottom: 0;
                        position: fixed;
                        top: 0;
                        width: 84vw;
                        z-index: 9;

                        @media (max-width: $sp-breakpoint-max) {
                            left: 11px;
                            top: 0;
                            width: 93vw;
                        }

                        &.open {
                            display: block;
                            height: 61vh;
                            overflow: auto;
                        }

                        .link {
                            align-items: center;
                            border-top: 1px solid #c3c3c3;
                            display: flex;
                            font-size: 16px;
                            margin: 0;
                            padding: 7px 0;

                            @media (max-width: $sp-breakpoint-max) {
                                font-size: 14px;
                            }

                            .link-image {
                                background-size: cover;
                                height: 65px;
                                margin-right: 20px;
                                width: 59px;

                                @media (max-width: $sp-breakpoint-max) {
                                    height: 40px;
                                    width: 37px;
                                }
                            }

                            a {
                                padding-bottom: 0 !important;
                            }
                        }

                        h4 {
                            color: #6d797a;
                            font-size: 17px;
                            font-weight: 100;
                            margin-bottom: 29px;
                            margin-top: 32px;

                            @media (max-width: $sp-breakpoint-max) {
                                font-size: 14px;
                                margin-bottom: 24px;
                                margin-top: 27px;
                            }

                            .close {
                                height: 32px;
                                position: absolute;
                                right: 32px;
                                top: 30px;
                                width: 32px;

                                @media (max-width: $sp-breakpoint-max) {
                                    top: 25px;
                                }

                                &::before,
                                &::after {
                                    background-color: #815b30;
                                    content: ' ';
                                    height: 21px;
                                    left: 15px;
                                    position: absolute;
                                    width: 2px;
                                }

                                &::before {
                                    transform: rotate(45deg);
                                }

                                &::after {
                                    transform: rotate(-45deg);
                                }
                            }
                        }
                    }
                }

                .category,
                .link {
                    .link-icon {
                        cursor: pointer;

                        &::before {
                            border-top: 1px solid $golden;
                        }

                        &::after {
                            border-right: 1px solid $golden;
                            border-top: 1px solid $golden;
                            content: '';
                            height: 6px;
                            left: 8px;
                            position: absolute;
                            top: 7.5px;
                            transform: rotate(45deg);
                            width: 6px;
                        }
                    }
                }
            }
        }
    }
}
